import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { FaHeadset, FaSignOutAlt } from 'react-icons/fa';
import { MdOutlineAccountCircle, MdAccountBalance } from 'react-icons/md';
import { IoMdNotificationsOutline } from 'react-icons/io';
import { BiTrip } from "react-icons/bi";
import { IoChevronBackCircleOutline } from "react-icons/io5";
import { IoHome } from "react-icons/io5";
import { Helmet } from 'react-helmet';
import PropTypes from 'prop-types'; // Import PropTypes
import '../static/css/master.css'; 
import logo from '../static/images/ss-original.png'; 
import { decryptData } from '../../utils/encryption'; 

import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css'; 

// Personal details
const firstName = decryptData('storageData')?.firstName;
const lastName = decryptData('storageData')?.lastName;
const email = decryptData('storageData')?.email;

const MasterPage = ({ children }) => {
  const [isMenuOpen, setIsMenuOpen] = useState(true);
  const [availability, setAvailability] = useState(true);
  const [title, setTitle] = useState('Driver Dashboard'); // Dynamic title state
  const navigate = useNavigate();
  const location = useLocation(); // Get current location

  // Effect to update the title based on the current route
  useEffect(() => {
    const titleMap = {
      '/driver-dashboard': 'Home',
      '/profile': 'Profile',
      '/support': 'Support',
      '/trips': 'Notification',
      '/earnings': 'Earnings',
    };
    setTitle(titleMap[location.pathname] || 'Welcome to Storage Sprint');
  }, [location.pathname]); // Re-run effect on path change


  const NotificationClick = () => {
    navigate('/notifications');
  };

  const handleProfileClick = () => {
    navigate('/profile');
  };

  const handleEarnigs = () => {
    navigate('/earnings/');
  };

  const handleTripsClick = () => {
    navigate('/available-trips');
  };

  const handleSupportClick = () => {
    navigate('/support');
  };

  const handleLogout = async () => {
    try {
      const response = await fetch('/api/logout/', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        credentials: 'include',
        body: JSON.stringify({ email: email }),
      });

      if (response.ok) {
        localStorage.clear();
        navigate('/');
      } else {
        alert('Logout failed. Please try again.');
      }
    } catch (error) {
      alert('An error occurred during logout. Please try again later.');
    }
  };

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };
  const handleAvailabilityChange = async () => {
    const newAvailability = !availability; // Toggle the availability
    setAvailability(newAvailability); // Update state optimistically
  
    try {
      const response = await fetch('/api/is-available/', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        credentials: 'include',
        body: JSON.stringify({ email: email, isAvailable: newAvailability }),
      });
  
      if (response.ok) {
        console.log('Availability updated successfully:', newAvailability);
        toast.success(response.success || 'Availability updated successfully!');
      } else {
        toast.error(response.statusText || 'Failed to update availability. Try again later.');
        setAvailability(!newAvailability); // Revert state if API fails
      }
    } catch (error) {
      // Handle the error here
      toast.error(error.message || 'Error updating availability. Please try again later.');
      setAvailability(!newAvailability); // Revert state on error
    }
  };
  
  

  return (
    <>
      <Helmet>
        <title>{title} Dashboard | Storage Sprint</title> {/* Dynamic title */}
      </Helmet>

      <div className="mainContainer">
        {/* Top Navigation Bar */}
        <header className="topHeader">
          <img src={logo} alt="Logo" className="topHeaderLogo" />
          <div className="topHeaderContent">
            <div className="hamburger" onClick={toggleMenu} aria-label="Toggle Menu">
              &#9776; {/* Hamburger Icon */}
            </div>
            <div className="userDetailsContainer">
            <a href='/profile' className="topNavProfile">
              {firstName.toUpperCase()} {lastName.toUpperCase()}
            </a> &nbsp;

              <div onClick={NotificationClick}>
                <IoMdNotificationsOutline className="topIcon" />
              </div>
              <div className="logout" onClick={handleLogout}>
                <FaSignOutAlt className="topIcon" />
              </div>
            </div>
          </div>
        </header>

        {/* Sidebar Navigation */}
        <nav className={`sidebar ${isMenuOpen ? 'open' : 'closed'}`}>
          <div className="sidebarHeader">
            <img src={logo} alt="Logo" className="sidebarLogo" />

            <div className='sidebarToggle' onClick={toggleMenu} aria-label="Toggle Sidebar">
              <IoChevronBackCircleOutline className="sidebarIcon" />
              <span>Hide</span>
            </div>
          </div>
          <ul className="sidebarMenu">

          <div className="availability-wrapper">
            <label htmlFor="availabilityToggle">
              {availability ? "Available" : "Unavailable"}
            </label>
            <div
              className={`driver-slider ${availability ? 'available' : 'unavailable'}`}
              onClick={handleAvailabilityChange}
            ></div>
          </div>

          <li className={location.pathname === '/driver-dashboard' ? 'active' : ''} onClick={() => navigate('/driver-dashboard')}>
            <IoHome className="sidebarIcon" />
            <span>Home</span>
          </li>

          <li className={location.pathname === '/available-trips' ? 'active' : ''} onClick={handleTripsClick}>
            <BiTrip className="sidebarIcon" />
            <span>Available Trips</span>
          </li>

          <li className={location.pathname === '/notifications' ? 'active' : ''} onClick={NotificationClick}>
            <IoMdNotificationsOutline className="sidebarIcon" />
            <span>Notifications</span>
          </li>

          <li className={location.pathname === '/earnings' ? 'active' : ''} onClick={handleEarnigs}>
            <MdAccountBalance className="sidebarIcon" />
            <span>Earnings</span>
          </li>

          <li className={location.pathname === '/support' ? 'active' : ''} onClick={handleSupportClick}>
            <FaHeadset className="sidebarIcon" />
            <span>Support</span>
          </li>

          <li className={location.pathname === '/profile' ? 'active' : ''} onClick={handleProfileClick}>
            <MdOutlineAccountCircle className="sidebarIcon" />
            <span>Profile</span>
          </li>
        </ul>

        <div className="sidebarFooter"></div>
        </nav>

        <main className="mainContent">
          {children} {/* Render the child components passed from different pages */}
        </main>

        {/* <footer className="dashboardFooter">
          <div className="footerContent">
            <div className="footerLinks">
              <a href="/about">About Us</a>
              <a href="/privacy">Privacy Policy</a>
              <a href="/terms">Terms & Conditions</a>
            </div>
            <div className="copyright">
              <p>&copy; {new Date().getFullYear()} Storage Sprint. All rights reserved.</p>
            </div>
          </div>
        </footer> */}
      </div>
    </>
  );
};

// Define prop types for the MasterPage component
MasterPage.propTypes = {
  children: PropTypes.node.isRequired, // children must be a node and is required
};

export default MasterPage;
