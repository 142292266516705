import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types'; // Import PropTypes for validation
import '../static/css/trips.css';
import calendarIcon from '../static/images/calender.png';
import tripLogo from '../static/images/logo.png';
import axios from "axios";
import CustomerPage from '../../homeDashboard/templates/CustomerPage';
import DriverPage from '../../driverDashboard/template/DriverPage';
import { useNavigate } from "react-router-dom";
import { decryptData } from "../../utils/encryption";

const Notification = () => {
    const [upcomingTrips, setUpcomingTrips] = useState([]);
    const [pastTrips, setPastTrips] = useState([]);
    const [role, setRole] = useState(null);
    const [error, setError] = useState('');

    useEffect(() => {
        const roleData = decryptData('storageData')?.role;
        setRole(roleData);
    }, []);

    const MasterComponent = role === 'driver' || role === 'admin' ? DriverPage : CustomerPage;

    useEffect(() => {
        fetchTrips();
    }, []);

    const fetchTrips = async () => {
        try {
            const userEmail = decryptData('storageData')?.email;

            if (!userEmail) {
                setError('User email not found.');
                return;
            }

            const response = await axios.get(`/api/get-all-trips/${userEmail}`, {
                headers: {
                    'Content-Type': 'application/json'
                }
            });

            const data = response.data.tripInfo;

            // Filter trips by status
            const upcoming = data.filter(trip => 
                trip.status === 'Pending' || 
                trip.status === 'In Progress' || 
                trip.status === 'Driving to Pickup'
            );
            const past = data.filter(trip => 
                trip.status === 'Completed' || 
                trip.status === 'Failed'
            );

            setUpcomingTrips(upcoming);
            setPastTrips(past);
        } catch (error) {
            console.error('Error fetching trip data:', error);
            setError('Failed to load trip data.',error);
        }
    };

    return (
        <MasterComponent>
            <div className="trips-screen">
                <h1 className="trips-title">Trips</h1>

                {/* Display error if there is one */}
                {error && <p className="error-message">{error}</p>}

                {/* Upcoming Notification */}
                <section className="trip-section">
                    <h2 className="section-title">Upcoming</h2>
                    {upcomingTrips.length > 0 ? (
                        <div className="trip-list-scroll">
                            {upcomingTrips.map((trip) => (
                                <TripCard key={trip.reference} trip={trip} />
                            ))}
                        </div>
                    ) : (
                        <div className="empty-trip-card">
                            <div>
                                <p>No upcoming trips, schedule a trip...</p>
                            </div>
                            <img src={calendarIcon} alt="Calendar" className="calendar-image" />
                        </div>
                    )}
                </section>

                {/* Past Notification */}
                <section className="trip-section">
                    <h2 className="section-title">Past</h2>
                    {pastTrips.length > 0 ? (
                        <div className="trip-list-scroll">
                            {pastTrips.map((trip) => (
                                <TripCard key={trip.reference} trip={trip} />
                            ))}
                        </div>
                    ) : (
                        <div className="empty-trip-card">
                            <div>
                                <p>No past trips available.</p>
                            </div>
                        </div>
                    )}
                </section>
            </div>
        </MasterComponent>
    );
};

const TripCard = ({ trip }) => {
    const navigate = useNavigate();
    const tripId = trip.reference;

    // Format the date and time as required
    const tripDate = new Date(trip.date).toLocaleDateString('en-GB', {
        day: 'numeric',
        month: 'short',
        year: 'numeric',
    });

    const tripTime = new Date(`1970-01-01T${trip.time}`).toLocaleTimeString('en-GB', {
        hour: '2-digit',
        minute: '2-digit',
    });

    return (
        <div className="trip-card" onClick={() => navigate(`/trip-Details/${tripId}`)}>
            <img src={tripLogo} alt="Trip Logo" className="trip-logo" />
            <div className="trip-details">
                <div className="trip-info">
                    <p className="trip-address">From: {trip.pickupLocation}</p>
                    <p className="trip-address">To: {trip.dropoffLocation}</p>
                    <p className="trip-date">Date: {tripDate}, {tripTime}</p>
                    <p className="trip-driver">Driver: {trip.driverName || 'N/A'}</p>
                    <p className="trip-amount">R{trip.amount}</p>
                </div>
            </div>
        </div>
    );
};

// Add PropTypes validation for the trip prop
TripCard.propTypes = {
    trip: PropTypes.shape({
        reference: PropTypes.string.isRequired,
        date: PropTypes.string.isRequired,
        time: PropTypes.string.isRequired,
        pickupLocation: PropTypes.string.isRequired,
        dropoffLocation: PropTypes.string.isRequired,
        driverName: PropTypes.string,
        amount: PropTypes.number.isRequired,
    }).isRequired,
};

export default Notification;
