import React, { useState } from 'react';
import Logo from '../../logos/storage-sprint.png'; 
import '../static/css/ChatPage.css'; 
import backButtonImage from '../static/images/Cancel.png'; 
import { useNavigate } from "react-router-dom";
import { Helmet } from 'react-helmet';

const StartChat = () => {
    const [messages, setMessages] = useState([]);
    const [newMessage, setNewMessage] = useState('');

    const navigate = useNavigate();

    const handleSendMessage = async () => {
        if (newMessage.trim()) {
            const newMsg = { type: 'right', text: newMessage };
            setMessages([...messages, newMsg]);
            setNewMessage('');

            try {
                await fetch('/api/save-messages/', {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify(newMsg),
                });
            } catch (error) {
                console.error('Error saving message:', error);
            }
        }
    };

    const handleBack = () => {
        navigate(-1); 
    };

    return (
        <div className="start-chat-body">
            <Helmet>
      <title>Support chat - Storage Sprint</title>
      </Helmet>
            <div className="back-button" onClick={handleBack}>
                <img src={backButtonImage} alt="Back" className="back-button-image" />
            </div>
            <div className="start-chat-container">
                <div className="chat-container">
                    <img src={Logo} alt="Storage Sprint Logo" className="chat-logo" />
                    <div className="chat-header">
                        <h1>Leave a Message for Support</h1>
                    </div>
                    <div className="chat-messages">
                        {messages.map((msg, index) => (
                            <div
                                key={index}
                                className={`chat-message ${msg.type}`}
                            >
                                <p>{msg.text}</p>
                            </div>
                        ))}
                    </div>
                    <div className="send-message">
                        <input
                            type="text"
                            value={newMessage}
                            placeholder="Type your message..."
                            onChange={(e) => setNewMessage(e.target.value)}
                        />
                        <button onClick={handleSendMessage}>Send</button>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default StartChat;
