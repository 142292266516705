import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useJsApiLoader } from '@react-google-maps/api';
import styles from '../static/css/home-dashboard.module.css';
import { FaMapMarkerAlt, FaFlag } from 'react-icons/fa';
import ShowMap from './ShowMap';
import { Helmet } from 'react-helmet';
import CustomerPage from './CustomerPage';
import { decryptData } from '../../utils/encryption';

const googleMapsApiKey = "AIzaSyBSdqDfQrPcI_eL3wpXzgyTgitL7CYJITc";
const libraries = ["places"];

const HomeDashboard = () => {
  const { isLoaded } = useJsApiLoader({
    googleMapsApiKey,
    libraries,
  });

  const [pickupLocation, setPickupLocation] = useState('');
  const [dropoffLocation, setDropoffLocation] = useState('');
  const [suggestions, setSuggestions] = useState([]);
  const [currentField, setCurrentField] = useState(null);
  const [loading, setLoading] = useState(false);
  const [showNextButton, setShowNextButton] = useState(false);
  const [currentLocation, setCurrentLocation] = useState(null);
  const [locationError, setLocationError] = useState(null);

  const userData = decryptData('storageData');
  const firstName = userData?.firstName;
  const navigate = useNavigate();

  useEffect(() => {
    const fetchCurrentLocation = () => {
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(
          ({ coords: { latitude, longitude } }) => {
            setCurrentLocation({ lat: latitude, lng: longitude });
          },
          (error) => {
            console.error('Error getting location:', error);
            setLocationError('Unable to fetch location. Please enable location services.');
          }
        );
      } else {
        setLocationError('Geolocation is not supported by this browser.');
      }
    };
    fetchCurrentLocation();
  }, []);

  const fetchSuggestions = async (value) => {
    if (!window.google || !window.google.maps || !window.google.maps.places) return;

    const service = new window.google.maps.places.AutocompleteService();
    service.getPlacePredictions(
      { input: value, types: ['geocode'] },
      (predictions, status) => {
        if (status === window.google.maps.places.PlacesServiceStatus.OK) {
          setSuggestions(predictions.map((p) => p.description));
        }
      }
    );
  };

  const handleInputChange = (e, field) => {
    const value = e.target.value;
    if (field === 'pickup') {
      setPickupLocation(value);
      setCurrentField('pickup'); // Set current field
    } else {
      setDropoffLocation(value);
      setCurrentField('dropoff'); // Set current field
    }
    fetchSuggestions(value); // Fetch suggestions
  };

  const handleMapSelect = (location) => {
    if (currentField === 'pickup') {
      setPickupLocation(location);
      localStorage.setItem('pickupLocation', location);
    } else if (currentField === 'dropoff') {
      setDropoffLocation(location);
      localStorage.setItem('dropoffLocation', location);
    }
    setSuggestions([]); // Clear suggestions after selection
  };

  const handleSuggestionClick = (suggestion) => {
    handleMapSelect(suggestion); // Reuse handleMapSelect for consistency
  };

  const handleNext = () => {
    if (!pickupLocation || !dropoffLocation) {
      alert('Please fill in both Pickup and Dropoff locations.');
      return;
    }
    setShowNextButton(true);
  };

  const handleContinue = async () => {
    if (!pickupLocation || !dropoffLocation) {
      alert('Please fill in both Pickup and Dropoff locations.');
      return;
    }

    setLoading(true);

    localStorage.setItem('pickupLocation', pickupLocation);
    localStorage.setItem('dropoffLocation', dropoffLocation);

    await new Promise((resolve) => setTimeout(resolve, 1000));

    navigate('/confirm-trip', { state: { pickupLocation, dropoffLocation } });
    setLoading(false);
  };

  if (!isLoaded) return <p>Loading Google Maps...</p>;

  return (
    <CustomerPage>
      <Helmet>
        <title>Schedule a Delivery | Storage Sprint</title>
      </Helmet>
      <main>
        <div className={styles.mobileContainer}>
          <div className={styles.locationsContainer}>
            <h1>Welcome, {firstName}!</h1>
            {locationError && <p className={styles.error}>{locationError}</p>}
            <div className={styles.inputGroup}>
              <div className={styles.inputField}>
                <FaMapMarkerAlt />
                <input
                  type="text"
                  placeholder="Enter Pickup Location"
                  value={pickupLocation}
                  onChange={(e) => handleInputChange(e, 'pickup')}
                  onClick={() => setCurrentField('pickup')}
                />
              </div>
              <div className={styles.inputField}>
                <FaFlag />
                <input
                  type="text"
                  placeholder="Enter Dropoff Location"
                  value={dropoffLocation}
                  onChange={(e) => handleInputChange(e, 'dropoff')}
                  onClick={() => setCurrentField('dropoff')}
                />
              </div>
            </div>
            {suggestions.length > 0 && (
              <ul className={styles.suggestionsList}>
                {suggestions.map((suggestion, index) => (
                  <li key={index} onClick={() => handleSuggestionClick(suggestion)}>
                    {suggestion}
                  </li>
                ))}
              </ul>
            )}
            <div className={styles.mapContainer}>
              <ShowMap 
                pickupLocation={pickupLocation}
                dropoffLocation={dropoffLocation}
                currentLocation={currentLocation}
                animateFly={showNextButton}
                onLocationSelect={handleMapSelect} // Pass handleMapSelect to the map
              />
            </div>
            {showNextButton ? (
              <button className={styles.continueButton} onClick={handleContinue} disabled={loading}>
                {loading ? 'Loading...' : 'Continue to Package'}
              </button>
            ) : (
              <button className={styles.continueButton} onClick={handleNext}>
                Next
              </button>
            )}
          </div>
        </div>
      </main>
    </CustomerPage>
  );
};

export default HomeDashboard;