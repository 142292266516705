import React, { useState, useEffect } from 'react';
import DriverPage from './DriverPage';
import ConfirmModal from './ConfirmModal';  // Import the modal component
import '../static/css/tripProgress.css';
import { Helmet } from 'react-helmet';
import { FiMapPin, FiInfo, FiPhone} from 'react-icons/fi';
import { FaUser } from 'react-icons/fa';
import { decryptData } from '../../utils/encryption'; 

//trip reference
const tripReference =decryptData('tripdata');


const TripProgress = () => {
    const [tripDetails, setTripDetails] = useState(null);
    const [error, setError] = useState(null);
    const [status, setStatus] = useState('');
    const [modalOpen, setModalOpen] = useState(false); 
    const [selectedStatus, setSelectedStatus] = useState(''); 

  

    const statuses = [
        'Driving to Pickup',
        'Driving to Destination',
        'Completed',
        'Cancelled',
    ];

    useEffect(() => {
        if (!tripReference) {
            setError('Trip reference is missing');
            return;
        }

        const fetchTripDetails = async () => {
            try {
                const response = await fetch(`/api/trip-status/${tripReference}/`, {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json',
                        'credentials': 'include',
                    },
                });

                const data = await response.json();

                if (response.ok) {
                    setTripDetails(data);
                    setStatus(data.status);
                } else {
                    setError(data.error || 'Failed to load trip details.');
                }
            } catch (error) {
                setError('Error fetching trip details.');
            }
        };

        fetchTripDetails();

    });

    const openModal = (newStatus) => {
        setSelectedStatus(newStatus); // Store the status being changed to
        setModalOpen(true); // Open the modal
    };

    const handleStatusChange = async () => {
        setStatus(selectedStatus); // Apply the status change
        setModalOpen(false); // Close the modal

        try {
            const response = await fetch(`/api/update-trip-status/${tripReference}/`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ status: selectedStatus }),
            });

            if (!response.ok) {
                throw new Error('Failed to update status');
            }
        } catch (error) {
            console.error('Error updating status:', error);
            alert('Error updating status. Please try again.');
        }
    };

    if (error) {
        return (
            <DriverPage>
                <Helmet>
                    <title>Trip Progress</title>
                </Helmet>
                <div className="error-message">
                    <h1>Error</h1>
                    <p>{error}</p>
                </div>
            </DriverPage>
        );
    }

    if (!tripDetails) {
        return (
            <DriverPage>
                <Helmet>
                    <title>Trip Progress</title>
                </Helmet>
                <div className="loading-message">
                    <p>Loading trip details...</p>
                </div>
            </DriverPage>
        );
    }

    return (
        <DriverPage>
            <Helmet>
                <title>Trip Progress</title>
            </Helmet>
            <div className="trip-progress-page">
                <h1 className="page-title">Trip Progress</h1>
                <div className="trip-info-container">
                    <h2 className="section-title">Trip Details</h2>

                    

                    <div className="customer-details">
                    <div className="customer-info">
                        <FaUser className="icon" />
                        <strong>Customer Name:</strong>
                        <p>{tripDetails.customerName}</p>
                    </div>
                    
                    <div className="customer-info">
                        <FiPhone className="icon" />
                        <strong>Customer Number:</strong>
                        <p>{tripDetails.customerNumber}</p>
                    </div>

                    

                    <div className="customer-actions">
                        <a href={`tel:${tripDetails.customerNumber}`} target="_blank" rel="noopener noreferrer">
                            <FiPhone className="icon" />
                            Call Customer
                        </a>
                        {/* <a href={`sms:${tripDetails.customerNumber}`} target="_blank" rel="noopener noreferrer">
                            <FiMessageSquare className="icon" />
                            Send SMS
                        </a> */}
                    </div>
                </div>

                <div className="trip-detail">
                        <FiInfo size={20} style={{ marginRight: '10px' }} />
                        <strong>Trip Status:</strong>
                        <select
                            className={`trip-status ${status.toLowerCase()}`}
                            value={status}
                            onChange={(e) => openModal(e.target.value)} // Open modal instead of changing status directly
                        >
                            {statuses.map((statusOption) => (
                                <option key={statusOption} value={statusOption}>
                                    {statusOption}
                                </option>
                            ))}
                        </select>
                    </div>



                    <div className="trip-detail">
                        <FiMapPin size={20} style={{ marginRight: '10px' }} />
                        <strong>Pickup Location:</strong>
                        <a href={`https://www.google.com/maps/search/?api=1&query=${encodeURIComponent(tripDetails.pickupLocation)}`} target="_blank" rel="noopener noreferrer">
                            {tripDetails.pickupLocation}
                        </a>
                    </div>
                    <div className="trip-detail">
                        <FiMapPin size={20} style={{ marginRight: '10px' }} />
                        <strong>Dropoff Location:</strong>
                        <a href={`https://www.google.com/maps/search/?api=1&query=${encodeURIComponent(tripDetails.dropoffLocation)}`} target="_blank" rel="noopener noreferrer">
                            {tripDetails.dropoffLocation}
                        </a>
                    </div>

                </div>

                {/* Render the confirmation modal */}
                <ConfirmModal
                    isOpen={modalOpen}
                    onClose={() => setModalOpen(false)}
                    onConfirm={handleStatusChange}
                    message={`Are you sure you want to change the trip status to "${selectedStatus}"?`}
                />
            </div>
        </DriverPage>
    );
};

export default TripProgress;
