import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import liveChatLogo from '../static/images/imageChat.png';
import '../static/css/SupportPage.css';
import { Helmet } from 'react-helmet';
import CustomerPage from '../../homeDashboard/templates/CustomerPage';
import { decryptData } from '../../utils/encryption';
import DriverPage from '../../driverDashboard/template/DriverPage';
import tripLogo from '../../logos/storage-sprint.png';

const SupportPage = () => {
  const [visibleFAQ, setVisibleFAQ] = useState(null);
  const [showAllFAQs, setShowAllFAQs] = useState(false);
  const [trips, setTrips] = useState([]);
  const [showAllTrips, setShowAllTrips] = useState(false);
  const [selectedTrip, setSelectedTrip] = useState(null);
  const [helpType, setHelpType] = useState('');
  const [description, setDescription] = useState('');
  const navigate = useNavigate();
  const [role, setRole] = useState(null);  // Track the role

  useEffect(() => {
    const roleData = decryptData('storageData')?.role;
    setRole(roleData); 
  }, []);

  useEffect(() => {
    const fetchUserTrips = async () => {
      const email = decryptData('storageData')?.email;

      if (!email) {
        console.error('Email not found in local storage');
        return;
      }

      try {
        const response = await fetch(`/api/user-trips/?email=${email}`, {
          method: 'GET',
          headers: {
            'X-CSRFToken': window.csrfToken || '',
          },
          credentials: 'include',
        });

        if (!response.ok) {
          const errorData = await response.json();
          throw new Error(errorData.error || 'Something went wrong');
        }

        const data = await response.json();
        setTrips(data.trips);
      } catch (error) {
        console.error('Error fetching user trips:', error.message);
      }
    };

    fetchUserTrips();
  }, []);

  const toggleFAQ = (index) => {
    setVisibleFAQ(prevIndex => (prevIndex === index ? null : index));
  };

  const handleLiveChat = () => {
    navigate('/chat/');
  };

  const toggleAllFAQs = () => {
    setShowAllFAQs(prev => !prev);
  };

  const toggleAllTrips = () => {
    setShowAllTrips(prev => !prev);
  };

  const handleSelectTrip = (trip) => {
    setSelectedTrip(trip);
    setHelpType('');
    setDescription('');
  };

  const handleSubmitSupportRequest = async () => {
    if (!helpType || !description) {
      alert("Please select a help type and provide a description.");
      return;
    }

    try {
      const response = await fetch('/api/support-request/', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'X-CSRFToken': window.csrfToken || '',
        },
        body: JSON.stringify({
          reference: selectedTrip.reference,
          help_type: helpType,
          description: description,
        }),
      });

      if (response.ok) {
        alert('Support request submitted successfully!');
        setSelectedTrip(null);
        setHelpType('');
        setDescription('');
      } else {
        alert('Failed to submit support request.');
      }
    } catch (error) {
      console.error('Error submitting support request:', error);
      alert('Error submitting support request.');
    }
  };

  if (!role) {
    return <div>Loading...</div>;  
  }

  const MasterComponent = role === 'driver' || role === 'admin' ? DriverPage : CustomerPage;

  return (
    <MasterComponent>
      <div className="support-body">
        <Helmet>
          <title>Support - Storage Sprint</title>
        </Helmet>
        <header className="support-header">
          <h1>Welcome to Storage Sprint's Support</h1>
          <p>We&apos;re here to help. Explore support resources below.</p>
        </header>
        <section className="choose-trip-section">
            <h3 className="support-highlight-text">Choose Trip</h3>
            <div className="trip-list">
              {trips.slice(0, showAllTrips ? trips.length : 2).map((trip) => (
                <li
                  key={trip.reference}
                  className={`trip-item ${selectedTrip?.reference === trip.reference ? 'selected' : ''}`}
                  onClick={() => handleSelectTrip(trip)}
                >
                  <div className="trip-details">
                    <img src={tripLogo} alt="Trip Logo" className="trip-logo" />
                    <p>Date: {trip.date}</p>
                    <p>From: {trip.pickupLocation}</p>
                    <p>To: {trip.dropoffLocation}</p>
                    <p>Price: R{trip.amount}</p>
                  </div>
                </li>
              ))}
            </div>
            {trips.length > 2 && (
              <a href="#view-all" className="support-view-all" onClick={toggleAllTrips}>
                {showAllTrips ? 'View Less' : 'View All'}
              </a>
            )}
          </section>

          {selectedTrip && (
            <div className="support-help-section">
              <h4>Help Type</h4>
              <select value={helpType} onChange={(e) => setHelpType(e.target.value)}>
                <option value="">Select Help Type</option>
                <option value="fare-review">Fare Review</option>
                <option value="damaged-item">Damaged Item</option>
                <option value="lost-item">Lost Item</option>
                <option value="other">Other</option>
              </select>

              {helpType && (
                <>
                  <h4>Description</h4>
                  <textarea
                    value={description}
                    onChange={(e) => setDescription(e.target.value)}
                    placeholder="Describe your issue"
                  />
                </>
              )}

              {helpType && description && (
                <button onClick={handleSubmitSupportRequest}>
                  Submit Support Request
                </button>
              )}
            </div>
          )}

          <section className="faqs">
            <h2 className="support-highlight-text">FAQs</h2>
            <ul>
              <li onClick={() => toggleFAQ(0)}>
                Can I schedule a ride in advance?
                {visibleFAQ === 0 && <p className="faq-answer">Yes, you can schedule a ride in advance on Storage Sprint.</p>}
              </li>
              <li onClick={() => toggleFAQ(1)}>
                What should I do if I left something in the car?
                {visibleFAQ === 1 && <p className="faq-answer">Contact the driver immediately.</p>}
              </li>
              {showAllFAQs && (
                <>
                  <li onClick={() => toggleFAQ(2)}>
                    How do I use the app?
                    {visibleFAQ === 2 && <p className="faq-answer">You can schedule rides, view trip history, and contact support through the app.</p>}
                  </li>
                  <li onClick={() => toggleFAQ(3)}>
                    How do I request a ride?
                    {visibleFAQ === 3 && <p className="faq-answer">Select your pickup and drop-off locations, then confirm your ride request in the app.</p>}
                  </li>
                </>
              )}
            </ul>
            <a href="#view-all" className="support-view-all" onClick={toggleAllFAQs}>
              {showAllFAQs ? 'View Less' : 'View All'}
            </a>

            {/* Leave a Message Option under FAQs */}
            {showAllFAQs && (
              <div className="message-chat">
                <p>If you still have questions, feel free to</p>
                <a href="#start-chat" onClick={handleLiveChat}>
                  Leave a message
                  <img src={liveChatLogo} alt="Leave a Message Logo" className="message-chat-logo" />
                </a>
              </div>
            )}
          </section>
      </div>
    </MasterComponent>
  );
};

export default SupportPage;
 
 