import React, { useState, useEffect } from 'react';
import DriverPage from './DriverPage';
import { Helmet } from 'react-helmet';
import { FiMapPin, FiInfo, FiPhone } from 'react-icons/fi';
import { FaUser } from 'react-icons/fa';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import ConfirmModal from './ConfirmModal';
import '../static/css/tripProgress.css';
import { decryptData } from "../../utils/encryption";

const TripProgress = () => {
  const [tripDetails, setTripDetails] = useState(() => decryptData("tripdata") || null);
  const [error, setError] = useState(null);
  const [status, setStatus] = useState('');
  const [modalOpen, setModalOpen] = useState(false);
  const [selectedStatus, setSelectedStatus] = useState('');
  const tripReference = localStorage.getItem('tripReference');
  
  const statuses = ['Driving to Pickup', 'Driving to Destination', 'Completed', 'Cancelled'];

  useEffect(() => {
    if (!tripReference) {
      setError('Trip reference is missing');
      return;
    }
  
    const fetchTripDetails = async () => {
      try {
        const response = await fetch(`/api/trip-status/${tripReference}/`);
        const data = await response.json();
  
        if (response.ok) {
          setTripDetails(data);
          setStatus(data.status);
        } else {
          throw new Error(data.error || 'Failed to load trip details.');
        }
      } catch (error) {
        setError('Error fetching trip details.');
        toast.error('Error fetching trip details. Please try again later.');
      }
    };
  
    fetchTripDetails();
  }, [tripReference]); // Ensure it refetches only when tripReference changes
  
  

  const openModal = (newStatus) => {
    setSelectedStatus(newStatus);
    setModalOpen(true);
  };

  const handleStatusChange = async () => {
    setModalOpen(false);
  
    try {
      const response = await fetch(`/api/update-trip-status/${tripReference}/`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ status: selectedStatus }),
      });
  
      const data = await response.json();
  
      if (!response.ok) throw new Error(data.error || 'Failed to update status');
  
      setStatus(selectedStatus);
      toast.success(data.message || 'Trip status updated successfully!'); // Use API response message
    } catch (error) {
      toast.error(error.message || 'Error updating status. Please try again.');
    }
  };

  
  if (error) {
    return (
      <DriverPage>
        <Helmet><title>Trip Progress | Error</title></Helmet>
        <div className="error-message">{error}</div>
      </DriverPage>
    );
  }

  if (!tripDetails) {
    return (
      <DriverPage>
        <Helmet><title>Trip Progress</title></Helmet>
        <div className="loading-message">Loading trip details...</div>
      </DriverPage>
    );
  }

  return (
    <DriverPage>
      <Helmet><title>Trip Progress</title></Helmet>
      <div className="trip-progress-page premium">
        <h1 className="page-title">Trip Progress</h1>
        <div className="trip-info-container">
          <h2 className="section-title">Trip Details</h2>
          <div className="customer-details">
            <div className="customer-info">
              <FaUser className="icon" />
              <strong>Customer Name:</strong>
              <p>{tripDetails.customerName}</p>
            </div>
            <div className="customer-info">
              <FiPhone className="icon" />
              <strong>Customer Number:</strong>
              
              <p>{tripDetails.customerNumber}</p>
            </div>
            <div className="customer-actions">
              <a href={`tel:${tripDetails.customerNumber}`} className="call-button premium-button">
                <FiPhone className="icon" /> Call Customer
              </a>
            </div>
          </div>
          <div className="trip-detail premium-detail">
            <FiInfo className="icon" />
            <strong>Trip Status:</strong>
            <select className="trip-status" value={status} onChange={(e) => openModal(e.target.value)}>
              {statuses.map((option) => (
                <option key={option} value={option}>{option}</option>
              ))}
            </select>
          </div>
          <div className="trip-detail premium-detail">
            <FiMapPin className="icon" />
            <strong>Pickup:</strong>
            <a href={`https://www.google.com/maps/search/?api=1&query=${encodeURIComponent(tripDetails.pickupLocation)}`}>
              {tripDetails.pickupLocation}
            </a>
          </div>
          <div className="trip-detail premium-detail">
            <FiMapPin className="icon" />
            <strong>Dropoff:</strong>
            <a href={`https://www.google.com/maps/search/?api=1&query=${encodeURIComponent(tripDetails.dropoffLocation)}`}>
              {tripDetails.dropoffLocation}
            </a>
          </div>
        </div>
        <ConfirmModal
          isOpen={modalOpen}
          onRequestClose={() => setModalOpen(false)}
          title="Confirm Status Change"
          message={`Change status to: ${selectedStatus}?`}
          onConfirm={handleStatusChange}
        />
      </div>
    </DriverPage>
  );
};

export default TripProgress;
