import React, { useState, useEffect } from 'react';
import CustomerPage from '../../homeDashboard/templates/CustomerPage';
import DriverPage from '../../driverDashboard/template/DriverPage';
import { decryptData } from "../../utils/encryption";
import "../static/css/notifications.css";
import {Helmet} from "react-helmet";

const Notification = () => {

    const [role, setRole] = useState(null);
    const [notifications, setNotifications] = useState([]);
    const email = decryptData('storageData')?.email;
    const name = decryptData('storageData')?.firstName;

    useEffect(() => {
        const roleData = decryptData('storageData')?.role;

        setRole(roleData);
    }, []);

    const MasterComponent = role === 'driver' || role === 'admin' ? DriverPage : CustomerPage;

    useEffect(() => {
        const fetchNotifications = async () => {
            try {
                const response = await fetch(`/api/notifications/?email=${email}`, {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': 'Bearer <your-token>' // Include the token if authentication is required
                    }
                });
                const data = await response.json();

                if (data.notifications && Array.isArray(data.notifications)) {
                    setNotifications(data.notifications);
                }

            } catch (error) {
                console.error('Error fetching notifications:', error);
            }
        };

        fetchNotifications();
    }, [email]);

    const formatCreatedAt = (createdAt) => {
        const date = new Date(createdAt);
        const now = new Date();
        const diffInMs = now - date;
        const diffInHours = diffInMs / (1000 * 60 * 60);

        if (diffInHours < 24) {
            return date.toLocaleTimeString('en-GB', { hour: '2-digit', minute: '2-digit' });
        } else {
            return date.toLocaleDateString('en-GB', { day: '2-digit', month: '2-digit', year: 'numeric' });
        }
    };


    const handleNotificationClick = async (notificationId) => {
        try {
            const response = await fetch(`api/notifications/mark-as-read/${notificationId}/?email=${email}`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer <your-token>' // Include the token if authentication is required
                }
            });

            if (!response.ok) {
                throw new Error(`HTTP error! status: ${response.status}`);
            }

            setNotifications(notifications.map(notif =>
                notif.id === notificationId ? { ...notif, is_read: true } : notif
            ));
        } catch (error) {
            console.error('Error marking notification as read:', error);
        }
    };


    return (
        <MasterComponent>
            <div className="notifications-container">
                <Helmet>
                    <title>Notifications | Storage Sprint</title>
                </Helmet>
                <h2 className="notifications-title">Notifications</h2>
                {notifications.map((notif, index) => (
                    <div
                        className={`notification-card ${notif.is_read ? 'read' : 'unread'}`}
                        key={index}
                        onClick={() => handleNotificationClick(notif.id)}
                    >
                        <p className="notification-greeting">Hi {name}</p>
                        <p className="notification-message">{notif.message}</p>
                        <p className="notification-time">{formatCreatedAt(notif.created_at)}</p>
                    </div>
                ))}
            </div>
        </MasterComponent>
    );
};


export default Notification;
