import React from "react";
import Footer from "./Footer";
import NavBar from "./NavBar";
import "../static/Service.css";
import Logo from '../../logos/storage-sprint.png';
import {Helmet} from "react-helmet";

const TermsAndCondtions = () => {
    return (<div>
          <Helmet>
                <title>Terms and Conditions | Storage Sprint</title> 
        </Helmet>
        <NavBar/>
        <div className="TandCs-container">
        <div className="TandCs-image">
            <img src={Logo}
            alt={Logo}/>
        </div>
        <div className="TandCs-text">
        <h2>Terms and Conditions for Storage Sprint</h2>
            <ol className="TandCs-categories">
                <li>
                Contractual Relationship
                </li>
                <p>
                These Terms of Use ("Terms") govern your access to and use of the Storage Sprint platform, including our mobile application, website, content, and services (collectively, the "Services"). Storage Sprint (referred to as "we," "our," or "us") is a South African-based entity dedicated to providing innovative storage and logistics solutions.   
                </p>
                <h3>PLEASE READ THESE TERMS CAREFULLY BEFORE ACCESSING OR USING OUR SERVICES.</h3>
                <p>By accessing or using our Services, you agree to be bound by these Terms, forming a contractual relationship between you and Storage Sprint. If you do not agree, you may not access or use our Services. We reserve the right to modify or terminate these Terms or any part of the Services at any time for any reason.</p>
                <p>Supplemental terms may apply to specific services or promotions and will be disclosed when relevant. Such supplemental terms are part of these Terms and will prevail in case of a conflict. Storage Sprint may update these Terms periodically, and your continued use of the Services after changes are posted constitutes acceptance of the updated Terms.</p>
                <p>Our handling of personal information is outlined in our Privacy Policy, available on our website.</p>
                <li>
                Services Overview
                </li>
                <p>The Services provide a technology platform enabling users to schedule and arrange storage and logistics solutions through our mobile application or website. These solutions may include transportation, storage booking, and item delivery. Storage Sprint facilitates connections between users and independent service providers ("Service Providers") for fulfilling storage and logistics needs.</p>
                <p><b>Important</b>: Storage Sprint does not directly provide transportation, or logistics services. All such services are delivered by independent contractors, and we are not liable for their actions, omissions, or any disputes arising between you and them.</p>
                <li>
                License and Use 
                </li>
                <p>We grant you a limited, non-exclusive, non-transferable, revocable license to access and use the Services for personal, non-commercial purposes. All intellectual property rights in the Services remain with Storage Sprint or its licensors. Unauthorized reproduction, modification, or exploitation of our Services is strictly prohibited.</p>
                <li>
                User Accounts
                </li>
                <p>To access most Services, you must create and maintain an active user account ("Account"). You must:</p>
                    <ul className="TandCs-subcategories">
                        <li>Be at least 18 years old.
                        </li>
                        <li>Provide accurate and complete information, including a valid payment method.</li>
                        <li>Maintain the confidentiality of your account credentials.</li>
                    </ul>
                    <p>You are responsible for all activities under your account. Misuse or fraudulent activity may result in account suspension or termination.</p>
                <li>
                User Responsibilities
                </li>
                <p>You agree to:</p>
                    <ul className="TandCs-subcategories">
                        <li>
                            Use the Services for lawful purposes only.
                        </li>
                        <li>
                            Avoid causing damage, inconvenience, or disruption to Service Providers, other users, or the platform.
                        </li>
                        <li>
                            Comply with applicable laws and regulations.
                        </li>
                        <li>
                            Provide valid identification when requested.
                        </li>

                    </ul>
                <li>
                Third-Party Services   
                </li>
                <p>The Services may integrate with third-party platforms or content. Storage Sprint is not responsible for the availability, accuracy, or quality of third-party services and disclaims all liability arising from their use.</p>
                <li>
                Promotional Offers
                </li>
                <p>Storage Sprint may provide promotional codes or offers. These are subject to specific terms, including expiration dates and usage restrictions. Misuse or unauthorized distribution of promotional codes is prohibited and may result in their deactivation.</p>
                <li>
                User-Generated Content
                </li>
                <p>By submitting feedback, reviews, or other content ("User Content") to Storage Sprint, you grant us a worldwide, royalty-free license to use, modify, and distribute this content in connection with our Services. You represent that you own or have the rights to the content provided and that it does not violate any third-party rights.</p>
                <li>
                Payment and Fees
                </li>
                <p>All charges for Services, including transportation, storage, and logistics, are displayed in the application before booking. Payment is processed securely through accepted methods. Additional fees may apply for factors such as high demand,  or extended distances.</p>
                <p>Failure to process payment may result in service suspension or cancellation.</p>
                <li>
                Disclaimers and Limitations of Liability
                </li>
                <p>Storage Sprint provides the Services "as is" and does not guarantee uninterrupted or error-free operation. We disclaim all liability for actions of Service Providers or third parties. To the fullest extent permitted by law, our liability for any claim is limited to the amount paid for the Services in question.</p>
                <li>
                Modifications and Termination
                </li>
                <p>Storage Sprint provides the Services "as is" and does not guarantee uninterrupted or error-free operation. We disclaim all liability for actions of Service Providers or third parties. To the fullest extent permitted by law, our liability for any claim is limited to the amount paid for the Services in question.</p>
                <li>
                Governing Law
                </li>
                <p>These Terms are governed by the laws of South Africa. Any disputes will be resolved in accordance with South African legal procedures.</p>
                <li>
                Contact Us
                </li>
                <p>For questions or concerns regarding these Terms, please contact us at support@storagesprint.co.za.</p>
            </ol>
        </div>
        </div>
        <Footer/>
        </div>);
};

export  default TermsAndCondtions;