import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types'; // Import PropTypes
import { useNavigate, useLocation } from 'react-router-dom';
import { FaHeadset, FaSignOutAlt } from 'react-icons/fa';
import { MdOutlineAccountCircle } from 'react-icons/md';
import { IoMdNotificationsOutline } from 'react-icons/io';
import { BiTrip } from "react-icons/bi";
import { IoChevronBackCircleOutline } from "react-icons/io5";
import { Helmet } from 'react-helmet';
import '../static/css/masterCustomer.css'; 
import logo from '../static/images/ss-original.png'; 
import { decryptData } from '../../utils/encryption'; 

// Personal details
const email = decryptData('storageData')?.email;



const CustomerPage = ({ children }) => {
  const [isMenuOpen, setIsMenuOpen] = useState(true);
  const [title, setTitle] = useState('Driver Dashboard'); // Dynamic title state
  const navigate = useNavigate();
  const location = useLocation(); // Get current location

  // Effect to update the title based on the current route
  useEffect(() => {
    const titleMap = {
      '/': 'Home',
      '/profile': 'Profile',
      '/support': 'Support',
      '/dashboard': 'Request Trip'
    };
    setTitle(titleMap[location.pathname] || 'Welcome to Storage Sprint');
  }, [location.pathname]); // Re-run effect on path change

  const handleProfileClick = () => {
    navigate('/profile');
  };

  const handleRequestTrip = () => {
    navigate('/dashboard');
  };

  const handleTripsClick = () => {
    navigate('/notifications');
  }

  const handleSupportClick = () => {
    navigate('/support');
  };

  const handleLogout = async () => {
    try {
      const response = await fetch('/api/logout/', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        credentials: 'include',
        body: JSON.stringify({ email: email }),
      });

      if (response.ok) {
        localStorage.clear();
        navigate('/');
      } else {
        alert('Logout failed. Please try again.');
      }
    } catch (error) {
      alert('An error occurred during logout. Please try again later.');
    }
  };

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  return (
    <>
      <Helmet>
        <title>{title}</title> {/* Dynamic title */}
      </Helmet>

      <div className="mainContainer">
        {/* Top Navigation Bar */}
        <header className="topHeader">
          <img src={logo} alt="Logo" className="topHeaderLogo" />
          <div className="topHeaderContent">
            <div className="hamburger" onClick={toggleMenu} aria-label="Toggle Menu">
              &#9776; {/* Hamburger Icon */}
            </div>
            <div className="userDetailsContainer">
              <a href='/profile' className="topNavProfile">{decryptData('storageData')?.firstName.toUpperCase()} {decryptData('storageData')?.lastName.toUpperCase() }</a> &nbsp;
              <div>
                <IoMdNotificationsOutline className="topIcon" />
              </div>
              <div className="logout" onClick={handleLogout}>
                <FaSignOutAlt className="topIcon" />
              </div>
            </div>
          </div>
        </header>

        {/* Sidebar Navigation */}
        <nav className={`sidebar ${isMenuOpen ? 'open' : 'closed'}`}>
          <div className="sidebarHeader">
            <img src={logo} alt="Logo" className="sidebarLogo" />
            <div className='sidebarToggle' onClick={toggleMenu} aria-label="Toggle Sidebar">
              <IoChevronBackCircleOutline className="sidebarIcon" />
              <span>Hide</span>
            </div>
          </div>
          <ul className="sidebarMenu">
            <li onClick={handleRequestTrip}>
              <BiTrip className="sidebarIcon" />
              <span>Request Trip</span>
            </li>
            <li onClick={handleTripsClick}>
              <IoMdNotificationsOutline className="sidebarIcon" />
              <span>Notifications</span>
            </li>
            <li onClick={handleSupportClick}>
              <FaHeadset className="sidebarIcon" />
              <span>Support</span>
            </li>
            <li onClick={handleProfileClick}>
              <MdOutlineAccountCircle className="sidebarIcon" />
              <span>Profile</span>
            </li>
          </ul>
        </nav>

        <main className="mainContent">
          {children} {/* Render the child components passed from different pages */}
        </main>

        <footer className="dashboardFooter">
          <div className="footerContent">
            <div className="footerLinks">
              <a href="/about">About Us</a>
              <a href="/privacy">Privacy Policy</a>
              <a href="/terms">Terms & Conditions</a>
            </div>
            <div className="copyright">
              <p>&copy; {new Date().getFullYear()} Storage Sprint. All rights reserved.</p>
            </div>
          </div>
        </footer>
      </div>
    </>
  );
};

// PropTypes validation
CustomerPage.propTypes = {
  children: PropTypes.node.isRequired, // Validate children prop
};

export default CustomerPage;
