import React, { useEffect, useRef } from 'react';

const ShowMap = ({ pickupLocation, dropoffLocation, currentLocation }) => {
  const mapRef = useRef(null);

  useEffect(() => {
    const loadGoogleMaps = () => {
      if (window.google && window.google.maps) {
        return;
      }

      const script = document.createElement('script');
      script.src = `https://maps.googleapis.com/maps/api/js?key=AIzaSyCCxrbJP0caitwRZTBc0AK-2-UsgD2PUk0&callback=initMap&v=weekly`;
      script.async = true;
      script.defer = true;
      document.head.appendChild(script);
    };

    loadGoogleMaps();
  }, []);

  useEffect(() => {
    window.initMap = () => {
      const map = new window.google.maps.Map(mapRef.current, {
        center: currentLocation || { lat: -25.7565723, lng: 28.1913815 },
        zoom: 14,
        fullscreenControl: false,
        streetViewControl: false,
      });

      const addMarker = (position, title) => {
        return new window.google.maps.Marker({
          position,
          map,
          title,
        });
      };

      if (pickupLocation && dropoffLocation) {
        // Make sure pickupLocation and dropoffLocation are in correct format
        const [pickupLat, pickupLng] = pickupLocation.split(',').map(Number);
        const [dropoffLat, dropoffLng] = dropoffLocation.split(',').map(Number);

        // Add markers for pickup and dropoff
        addMarker({ lat: pickupLat, lng: pickupLng }, 'Pickup Location');
        addMarker({ lat: dropoffLat, lng: dropoffLng }, 'Dropoff Location');

        // Create and render polyline between the two locations
        const directionsService = new window.google.maps.DirectionsService();
        const directionsRenderer = new window.google.maps.DirectionsRenderer({
          suppressMarkers: true, // Avoid rendering markers since we already added them
          polylineOptions: { strokeColor: 'yellow', strokeWeight: 5 },
        });

        directionsRenderer.setMap(map);

        const request = {
          origin: new window.google.maps.LatLng(pickupLat, pickupLng),
          destination: new window.google.maps.LatLng(dropoffLat, dropoffLng),
          travelMode: window.google.maps.TravelMode.DRIVING,
        };

        directionsService.route(request, (result, status) => {
          if (status === window.google.maps.DirectionsStatus.OK) {
            directionsRenderer.setDirections(result);
          } else {
            console.error('Directions request failed due to ' + status);
          }
        });
      }
    };
  }, [pickupLocation, dropoffLocation, currentLocation]);

  return (
    <div
      ref={mapRef}
      style={{
        height: '400px',
        width: '100%',
        marginTop: '10px',
        marginBottom: '10px',
      }}
    />
  );
};

export default ShowMap;
