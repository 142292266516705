import React from "react";
import '../static/Footer.css';
import { useNavigate, useLocation } from "react-router-dom";

const Footer = () => {
    const location = useLocation();
    const navigate = useNavigate();
    
    const handleClick = (e, pageId) => {
        e.preventDefault(); // Prevent default anchor behavior
    
        if (location.pathname === "/") {
          // Scroll to the Services section if already on the Home page
          const pageSection = document.getElementById(pageId);
          if (pageSection) {
            pageSection.scrollIntoView({ behavior: "smooth" });
          }
        } else {
          // Navigate to /home and store a flag to scroll after navigation
          navigate("/", { state: { scrollToServices: pageId } });
        }
      };

    return (
        <div>{/* Footer */}
        <footer className="landpage-footer">
            <div className="landpage-footer-Content">
                <div className="landpage-footer-links">
                    <a href="#aboutus" onClick={(e) => handleClick(e, "aboutUs-section")}>About Us</a>
                    <a href="/privacy-policy">Privacy Policy</a>
                    <a href="/terms-and-conditions">Terms & Conditions</a>
                </div>
                <div className="Footer-copyright">
                    <p>&copy; {new Date().getFullYear()} Storage Sprint. All rights reserved.</p>
                </div>
            </div>
        </footer>
</div>
        );
};

export default Footer;