import React from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { FaMapMarkerAlt, FaRoad, FaMoneyBillAlt } from 'react-icons/fa';
import '../static/css/confirmTrip.css'; 
import { PaystackButton } from 'react-paystack';
import { green } from '@mui/material/colors';
import { decryptData } from '../../utils/encryption'; 

const email = decryptData('storageData')?.email;

const ConfirmTrip = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const pickupLocation = location.state?.pickupLocation || "Pickup Location not found";
  const dropoffLocation = location.state?.dropoffLocation || "Dropoff Location not found";
  const distance = location.state?.distance || "Distance not found";
  const amount = location.state?.amount || 0;
  const tripId = location.state?.reference || "Reference not found";
  const reference = location.state?.reference || "Reference not found";

  // Ensure process.env is defined for client-side code
  const paystackPublicKey = process.env.REACT_APP_PAYSTACK_PUBLIC_KEY || "pk_test_bd9178e45447a98147e2c022acaed49998b53e8a";

  if (!amount || !reference || !email) {
    return <p>Critical trip information is missing. Please go back and try again.</p>;
  }

  const paystackConfig = {
    email: email,
    amount: amount * 100,
    currency: 'ZAR',
    publicKey: paystackPublicKey,
    reference: reference,
    onSuccess: () => {
      navigate(`/trip-Details/${tripId}`);
    },
    onClose: () => {
      console.log('Payment closed');
    },
  };

  const handleEdit = () => {
    navigate(-1); 
  };

  return (
    <div className="confirm-trip-modern-container">
      <div className="modern-trip-details-card">
        <h1 className="modern-trip-title">Confirm Your Trip</h1>

        <div className="modern-detail-item">
          <FaMapMarkerAlt className="detail-icon" />
          <div className="detail-content">
            <h2 className="modern-detail-heading">Pickup Location:</h2>
            <p className="modern-detail-text">{pickupLocation}</p>
          </div>
        </div>

        <div className="modern-detail-item">
          <FaMapMarkerAlt className="detail-icon" />
          <div className="detail-content">
            <h2 className="modern-detail-heading">Dropoff Location:</h2>
            <p className="modern-detail-text">{dropoffLocation}</p>
          </div>
        </div>

        <div className="modern-detail-item">
          <FaRoad className="detail-icon" />
          <div className="detail-content">
            <h2 className="modern-detail-heading">Distance:</h2>
            <p className="modern-detail-text">{distance} Km</p>
          </div>
        </div>

        <div className="amount-highlight">
          <span className="amount-text-label">Amount:</span>
          <div className="amount-value-container">
            <FaMoneyBillAlt className="detail-icon" style={{ color: green[500] }} />
            <span className="amount-value">R{amount}</span>
          </div>
        </div>

        <div className="modern-trip-actions">
          <button className="modern-edit-button" onClick={handleEdit}>Edit Trip</button>
          {paystackPublicKey ? (
            <PaystackButton 
              className="modern-confirm-button" 
              {...paystackConfig} 
              text="Proceed to Payment"
            />
          ) : (
            <p>Payment cannot be processed at the moment.</p>
          )}
        </div>
      </div>
    </div>
  );
};

export default ConfirmTrip;
