import React, { useState  } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import '../static/css/PackageDescription.css'; 
import { decryptData } from '../../utils/encryption'; 
import CustomerPage from '../../homeDashboard/templates/CustomerPage';
import data from "../../homeDashboard/static/data/CategoryData"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus, faTrash } from "@fortawesome/free-solid-svg-icons";




const email = decryptData('storageData')?.email;

function PackageDescription() {
    const navigate = useNavigate();
    const location = useLocation(); 

    const pickupLocation = location.state?.pickupLocation || '';
    const dropoffLocation = location.state?.dropoffLocation || '';
    const [selectedProduct, setSelectedProduct] = useState('');
    const [cart, setCart] = useState([]);
    const [description, setDescription] = useState(''); 
    const [images, setImages] = useState(null); 
    const [loading, setLoading] = useState(false);
    const [expandedCategory, setExpandedCategory] = useState(null);
    const [expandedSubcategory, setExpandedSubcategory] = useState(null);
    const [quantity, setQuantity] = useState({});
    



    const toggleCategory = (categoryName) => {
      setExpandedCategory((prev) => (prev === categoryName ? null : categoryName));
      setExpandedSubcategory(null); // Reset subcategory when category changes
    };
  
    const toggleSubcategory = (subcategoryName) => {
      setExpandedSubcategory((prev) => (prev === subcategoryName ? null : subcategoryName));
    };
    
    const handleProductClick = (productName) => {
      setSelectedProduct(productName);
    };


  // Handles Adding to Cart (Only When Button is Clicked)
  const handleAddToCart = (product) => {
      setCart((prevCart) => {
        // Check if item already exists
        const existingItem = prevCart.find((item) => item.name === product);
    
       if (existingItem) {
         // Update existing item's quantity
         return prevCart.map((item) =>
           item.name === product ? { ...item, quantity: item.quantity + (quantity[product] || 1) } : item
         );
       } else {
         // Add new item
         return [...prevCart, { name: product, quantity: quantity[product] || 1 }];
       }
        });

  // Reset selection after adding
  setSelectedProduct(null);
};

      //Removes a product from the selected list
    const handleRemoveProduct = (productName) => {
      setCart(cart.filter(item => item.name !== productName));
      if (selectedProduct === productName) setSelectedProduct(null);
    };

      //Handles the changes of the selected product quantity
    const handleQuantityChange = (productName, newQuantity) => {
     
      // Update quantity for the selected product before adding
      setQuantity((prev) => ({
        ...prev,
        [productName]: newQuantity
      }));

      // If the product is already in the cart, update its quantity
      setCart((prevCart) =>
        prevCart.map((item) =>
          item.name === productName ? { ...item, quantity: newQuantity } : item
        )
      );

    };

    
   

    const handleImageUpload = (e) => {
        setImages(e.target.files);
    };

    
    // Function to find subcategory by product name
    const findSubcategory = (productName) => {
        for (const category of data.categories) {
            for (const subcategory of category.subcategories) {
                if (subcategory.products.includes(productName)) {
                    return subcategory.name;
                }
            }
        }
        return 'Unknown Subcategory';
    };

    const handleContinue = async () => {
            
        // Validate that at least one product is selected
        if (cart.length === 0) {
            alert('Please select at least one product before continuing.');
            return;
        }

        
        setDescription(cart);
    
        setLoading(true);
        try {
            const formData = new FormData();
            formData.append('description', description);
            formData.append('email', email);
            formData.append('pickupLocation', pickupLocation);
            formData.append('dropoffLocation', dropoffLocation);
            

            // Append each product from the cart
            cart.forEach((item) => {
              formData.append('products[]', item.name);
              formData.append('quantities[]', item.quantity);
            });
    
            
    
            if (images) {
                Array.from(images).forEach((image) => {
                    formData.append('images', image);
                });
            }
    
            const response = await fetch('/api/payment/initiate/', {
                method: 'POST',
                credentials: 'include',
                body: formData,
            });
    
            if (response.ok) {
                const data = await response.json();
                
                navigate('/confirm-trip', {
                    state: {
                        pickupLocation,
                        dropoffLocation,
                        distance: data.distance,
                        amount: data.amount,
                        reference: data.reference,
                    },
                });
            } else {
                const errorData = await response.json();
                alert('Error: ' + (errorData.message || 'An error occured while processing your request. Please try again later.'));
            }
        } 
        
        catch (error) {
            alert('Unable to connect to the server at this time.');
            console.error('An error occurred', error);
        } finally {
            setLoading(false);
        }
    };

    
    return (

        <CustomerPage>

        <div className="package-container" >
        
            <main className="main-content">
                <div className="input-row">
                    <input type="text" placeholder="From" value={pickupLocation} readOnly />
                    <input type="text" placeholder="To" value={dropoffLocation} readOnly />
                </div>

                <div className="accordion-menu">

                  <h2 className="title">Package Description</h2>
                  {data.categories.map((category) => (
                    <div key={category.name} className="category">
                      {/* Category Header */}
                      <div className="category-header" onClick={() => toggleCategory(category.name)}>
                        <span>{category.name}</span>
                        <span>{expandedCategory === category.name ? "▼" : "▶"}</span>
                      </div>
            
                      {/* Subcategories (Only show if expanded) */}
                      {expandedCategory === category.name && (
                        <div className="subcategories">
                          {category.subcategories.map((subcategory) => (
                            <div key={subcategory.name} className="subcategory">
                              <div className="subcategory-header" onClick={() => toggleSubcategory(subcategory.name)}>
                                <span>{subcategory.name}</span>
                                <span>{expandedSubcategory === subcategory.name ? "▼" : "▶"}</span>
                              </div>
            
                              {/* Products (Only show if expanded) */}
                              {expandedSubcategory === subcategory.name && (
                                <ul className="products">
                                  {subcategory.products.map((product) => (
                                    <li key={product}>
                                      <input
                                        type="radio"
                                        name="selectedProduct"
                                        checked={selectedProduct === product}
                                        onChange={() => handleProductClick(product)}
                                      />
                                      <label onClick={() => handleProductClick(product)}>{product}</label>

                                      {/* Show Quantity Input Only for Selected Product */}
                                      {selectedProduct === product && (
                                        <>
                                        <input
                                          type="number"
                                          className="quantity-field"
                                          value={quantity[product] || 1}
                                          min="1"
                                         onChange={(e) => handleQuantityChange(product, parseInt(e.target.value))}
                                         
                                        />
                                        {/* Add Item Button (Only Adds on Click) */}
                                        <button className="btn-Add-Item" onClick={() => handleAddToCart(product)} >
                                          <FontAwesomeIcon icon={faPlus} style={{ color: "black", fontSize: "16px" }} />
                                           Add Item
                                        </button>

                                        </>

                                      )}

                                    </li>
                                  ))}
                                </ul>
                              )}
                            </div>
                          ))}
                        </div>
                      )}
                    </div> ))}

                    

                </div>            
                

                  <label className="select-products">Added Items</label>

                  <table className="selected-products-table">
                    
                    <tbody>
                      {cart.map((item) => (
                        <tr key={item.name}>
                          <td>{item.name}</td>
                          <td>{findSubcategory(item.name)}</td>
                          <td>
                            <input
                              type="number"
                              className="quantity-field"
                              value={item.quantity}
                              min="1"
                              onChange={(e) => handleQuantityChange(item.name, parseInt(e.target.value))}
                            />
                          </td>
                          <td>
                            <button className="btn-Remove" onClick={() => handleRemoveProduct(item.name)}>
                              <FontAwesomeIcon icon={faTrash} style={{ color: "black", fontSize: "20px" }} />
                            </button>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>

                 {/* <ul className="list-selectedProducts">
                    {cart.map((item) => (
                        <li key={item.name}className="selected-products">
                          {item.name} ({findSubcategory(item.name)}): 
                            <input
                                type="number"
                                className="quantity-field"
                                value={item.quantity}
                                min="1"
                                onChange={(e) => handleQuantityChange(item.name, parseInt(e.target.value))}
                            />
                            <button className="btn-Remove" onClick={() => handleRemoveProduct(item.name)}>
                              <FontAwesomeIcon icon={faTrash} style={{ color: "black", fontSize: "24.5px" }} />
                            </button>
                        </li>
                    ))}
                </ul> */}

                <div className="upload-section">
                    <label>Upload images of the package</label>
                    <input type="file" multiple onChange={handleImageUpload} />
                </div>

                <button className="package-button" onClick={handleContinue} disabled={loading}>
                    {loading ? 'Loading...' : 'Continue'}
                </button>
            </main>
        </div>

        </CustomerPage>
    );
}

export default PackageDescription;
