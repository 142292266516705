import CryptoJS from 'crypto-js';

export function encryptData(key, data) {
  const encryptedData = CryptoJS.AES.encrypt(JSON.stringify(data), 'qVC89VO$pbG41*wAJC(#HX4OzL#Y1rE').toString();
  localStorage.setItem(key, encryptedData);
}

export function decryptData(key) {
  const encryptedData = localStorage.getItem(key);
  if (!encryptedData) {
    console.warn(`No data found for key: ${key}`);
    return null; // Return null if no data is found.
  }

  try {
    const bytes = CryptoJS.AES.decrypt(encryptedData, 'qVC89VO$pbG41*wAJC(#HX4OzL#Y1rE');
    const decryptedData = bytes.toString(CryptoJS.enc.Utf8);
    if (!decryptedData) {
      console.warn(`Decrypted data is empty for key: ${key}`);
      return null;
    }
    return JSON.parse(decryptedData);
  } catch (error) {
    console.error(`Error decrypting data for key: ${key}`, error);
    return null; // Return null in case of decryption failure
  }
}

