import '../static/css/verifyPayment.css';
import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import axios from 'axios';
import { Helmet } from 'react-helmet';

const VerifyPayment = () => {

  const location = useLocation();
  const [message, setMessage] = useState('Verifying payment...');
  const [status, setStatus] = useState('loading'); // 'loading', 'success', 'error'
  const [attempt, setAttempt] = useState(1); // For retry mechanism

  // Determine the reference to use
  const storedReference = location.state?.reference;

  useEffect(() => {
    const verifyPayment = async () => {
      setMessage('Verifying payment...');
      setStatus('loading');
      try {
        const response = await axios.get(`/api/payment/verify/${storedReference}/`);
        if (response.status === 200) {
          setMessage('Payment verified successfully.');
          setStatus('success');
        } else {
          throw new Error(response.data.message || 'Verification failed');
        }
      } catch (err) {
        console.error("Verification Error:", err); // Log the full error object

        // Extract error message for display
        let errorMessage = 'An unexpected error occurred.';
        if (err.response && err.response.data && err.response.data.message) {
          errorMessage = err.response.data.message;
        } else if (err.message) {
          errorMessage = err.message;
        }

        // Set the extracted error message
        setMessage(errorMessage);
        setStatus('error');
      }
    };

    verifyPayment();
  }, [storedReference, attempt]); // Include storedReference and attempt in the dependency array

  const retryVerification = () => {
    setAttempt((prev) => prev + 1); // Trigger re-verification
  };

  return (
    <>
      <Helmet>
        <title>Payment Verification</title>
      </Helmet>

      <div id="verify-payment">
        <div className="verification-container">
          <div className={`status-card ${status}`}>
            {status === 'loading' && <div className="spinner"></div>}
            <h1>{message}</h1>
            {status === 'success' && <p>Your payment reference is: {storedReference}</p>}
            {status === 'error' && (
              <div>
                <p>Please try again or contact support.</p>
                <button onClick={retryVerification} className="retry-button">
                  Retry Verification
                </button>
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default VerifyPayment;
