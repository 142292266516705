import React, { useState } from 'react';
import axios from 'axios';
import { Formik, Field, Form, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import { useNavigate } from 'react-router-dom';
import './static/css/userProfile.css'; 
import defaultProfilePhoto from './static/images/user.png'; 
import { MdVerified } from 'react-icons/md';
import { HiOutlinePhone } from "react-icons/hi";
import { MdOutlineMail } from "react-icons/md";
import { Helmet } from 'react-helmet';
import { FaCamera } from "react-icons/fa";
import { decryptData } from '../utils/encryption';


const UserProfilePage = () => {
    const [activeTab, setActiveTab] = useState('profile');
    const [isEditing, setIsEditing] = useState(false);
    const [profilePhoto, setProfilePhoto] = useState(defaultProfilePhoto);
    const [notification, setNotification] = useState({ type: '', message: '' });
    const navigate = useNavigate();

    const initialProfileValues = {
        email: decryptData('storageData')?.email,
        title: decryptData('storageData')?.title,
        firstName: decryptData('storageData')?.firstName,
        lastName: decryptData('storageData')?.lastName,
        phoneNumber: decryptData('storageData')?.phoneNumber,
        countryCode: decryptData('storageData')?.countryCode
    };

    const initialAddressValues = {
        unitNumber: '',
        complexName: '',
        streetNumber: '',
        streetName: '',
        suburb: '',
        city: '',
        postalCode: '',
        country: '',
        state: '',
    };

    const profileValidationSchema = Yup.object().shape({
        title: Yup.string().required('Title is required'),
        firstName: Yup.string().required('First name is required'),
        lastName: Yup.string().required('Last name is required'),
        phoneNumber: Yup.string().required('Phone number is required'),
    });

    const addressValidationSchema = Yup.object().shape({
        streetNumber: Yup.string().required('Street Number is required'),
        streetName: Yup.string().required('Street Name is required'),
        suburb: Yup.string().required('Suburb is required'),
        city: Yup.string().required('City is required'),
        postalCode: Yup.string().required('Postal Code is required'),
        country: Yup.string().required('Country is required'),
        state: Yup.string().required('State / Province is required'),
    });

    const passwordValidationSchema = Yup.object().shape({
        currentPassword: Yup.string().required('Current password is required'),
        newPassword: Yup.string().required('New password is required').min(6, 'Password must be at least 6 characters long'),
        confirmPassword: Yup.string()
            .oneOf([Yup.ref('newPassword'), null], 'Passwords must match')
            .required('Confirm password is required'),
    });

    const handleUpdate = async (values) => {
        try {
            const response = await fetch('/api/change-user-details/', {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                credentials: "include",
                body: JSON.stringify({ email: initialProfileValues.email, ...values }),
            });

            const data = await response.json();
            if (response.ok) {
                Object.keys(values).forEach(key => localStorage.setItem(key, values[key]));
                setNotification({ type: 'success', message: data.message || 'Profile updated successfully' });
                setIsEditing(false);
            } else {
                setNotification({ type: 'error', message: data.message || 'Failed to update Profile' });
            }
        } catch (error) {
            setNotification({ type: 'error', message: 'Error occurred while updating Profile' });
        }
    };

    const handleAddressUpdate = async (values) => {
        try {
            const response = await fetch('/api/change-address-details/', {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                credentials: "include",
                body: JSON.stringify(values),
            });

            const data = await response.json();
            if (response.ok) {
                setNotification({ type: 'success', message: data.message || 'Address updated successfully' });
            } else {
                setNotification({ type: 'error', message: data.message || 'Failed to update Address' });
            }
        } catch (error) {
            setNotification({ type: 'error', message: 'Error occurred while updating Address' });
        }
    };

    const handleProfilePhotoChange = (event) => {
        const file = event.target.files[0];
        const image = new FormData();
        image.append('profile_photo', file);
        image.append('email', initialProfileValues.email);

        axios.post('/api/change-profile-picture/', image, {
            headers: { 'Content-Type': 'multipart/form-data' }
        })
        .then(response => {
            setProfilePhoto(response.data.profile_photo || defaultProfilePhoto);
            setNotification({ type: 'success', message: 'Profile photo updated successfully' });
        })
        .catch(() => {
            setNotification({ type: 'error', message: 'Error uploading profile photo' });
        });
    };

    const handleChangePassword = async (values) => {
        try {
            const response = await fetch('api/change-password/', {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                credentials: "include",
                body: JSON.stringify({ oldPassword: values.currentPassword, email: initialProfileValues.email, newPassword: values.newPassword }),
            });

            const data = await response.json();
            if (response.ok) {
                setNotification({ type: 'success', message: data.message || 'Password changed successfully' });
            } else {
                setNotification({ type: 'error', message: 'Incorrect old password' });
            }
        } catch (error) {
            setNotification({ type: 'error', message: 'Error changing password' });
        }
    };

    const handleClose = () => navigate(-1);

    const renderProfileTab = () => (
        <>
            <div className="profile-photo-wrapper">
                <img src={profilePhoto} alt="Profile" className="profile-photo" />
                <div className="photo-edit-icon" onClick={() => document.getElementById('upload-input').click()}>
                    <FaCamera className="icon-camera" />
                </div>
                <input type="file" id="upload-input" accept="image/*" onChange={handleProfilePhotoChange} hidden />
            </div>
            <div className="profile-details">
                <div className="detail-item">
                    <HiOutlinePhone className="icon" />
                    <span>{initialProfileValues.phoneNumber}</span>
                </div>
                <div className="detail-item email-item">
                    <MdOutlineMail className="icon" />
                    <span>{initialProfileValues.email}</span>
                    <MdVerified className="verified-icon" />
                </div>
                <button className="btn primary-btn" onClick={() => setIsEditing(true)}>Edit Info</button>
            </div>
        </>
    );

    const renderEditingProfileTab = () => (
        <Formik
            initialValues={initialProfileValues}
            validationSchema={profileValidationSchema}
            onSubmit={handleUpdate}
        >
            {({ isSubmitting }) => (
                <Form className="profile-edit-form">
                    {['title', 'firstName', 'lastName', 'phoneNumber', 'countryCode'].map(field => (
                        <div key={field} className="form-group">
                            <label className="label">{field.charAt(0).toUpperCase() + field.slice(1)}</label>
                            <Field type="text" name={field} className="input" />
                            <ErrorMessage name={field} component="div" className="error-msg" />
                        </div>
                    ))}
                    <div className="button-group">
                        <button type="submit" className="btn primary-btn" disabled={isSubmitting}>Save Changes</button>
                        <button type="button" className="btn secondary-btn" onClick={() => setIsEditing(false)}>Cancel</button>
                    </div>
                </Form>
            )}
        </Formik>
    );

    const renderAddressTab = () => (
        <Formik
            initialValues={initialAddressValues}
            validationSchema={addressValidationSchema}
            onSubmit={handleAddressUpdate}
        >
            {({ isSubmitting }) => (
                <Form className="address-edit-form">
                    {['unitNumber', 'complexName', 'streetNumber', 'streetName', 'suburb', 'city', 'postalCode', 'country', 'state'].map(field => (
                        <div key={field} className="form-group">
                            <label className="label">{field.charAt(0).toUpperCase() + field.slice(1)}</label>
                            <Field type="text" name={field} className="input" />
                            <ErrorMessage name={field} component="div" className="error-msg" />
                        </div>
                    ))}
                    <div className="button-group">
                        <button type="submit" className="btn primary-btn" disabled={isSubmitting}>Save Address</button>
                    </div>
                </Form>
            )}
        </Formik>
    );

    const renderSecurityTab = () => (
        <Formik
            initialValues={{ currentPassword: '', newPassword: '', confirmPassword: '' }}
            validationSchema={passwordValidationSchema}
            onSubmit={handleChangePassword}
        >
            {({ isSubmitting }) => (
                <Form className="security-form">
                    {['currentPassword', 'newPassword', 'confirmPassword'].map(field => (
                        <div key={field} className="form-group">
                            <label className="label">{field.charAt(0).toUpperCase() + field.slice(1)}</label>
                            <Field type="password" name={field} className="input" />
                            <ErrorMessage name={field} component="div" className="error-msg" />
                        </div>
                    ))}
                    <div className="button-group">
                        <button type="submit" className="btn primary-btn" disabled={isSubmitting}>Change Password</button>
                    </div>
                </Form>
            )}
        </Formik>
    );

    return (
        <>
            <Helmet>
                <title>Profile | Fancy App</title>
            </Helmet>
            <div className="user-profile">
                <button className="close-btn" onClick={handleClose}>×</button>
                <h1 className="page-title">Personal Info</h1>

                <div className="tabs-container">
                    {['profile', 'address', 'security'].map(tab => (
                        <button
                            key={tab}
                            className={`tab-btn ${activeTab === tab ? 'active' : ''}`}
                            onClick={() => setActiveTab(tab)}
                        >
                            {tab.charAt(0).toUpperCase() + tab.slice(1)}
                        </button>
                    ))}
                </div>

                <div className="content-card">
                    {notification.message && (
                        <div className={`alert ${notification.type}`}>
                            {notification.message}
                        </div>
                    )}

                    {activeTab === 'profile' && !isEditing && renderProfileTab()}
                    {activeTab === 'profile' && isEditing && renderEditingProfileTab()}
                    {activeTab === 'address' && renderAddressTab()}
                    {activeTab === 'security' && renderSecurityTab()}
                </div>

      
            </div>

            
        </>
    );
};

export default UserProfilePage;
