import React, {useEffect, useState,useMemo} from 'react';
import '../static/css/RiderSummary.css'; // Import CSS for styling
import { useNavigate } from 'react-router-dom';
import DriverPage from "./DriverPage";
import {decryptData} from "../../utils/encryption";
import {Helmet} from "react-helmet";

const RideSummary = () => {
    const navigate = useNavigate();
    const [totalAmount, setTotalAmount] = useState(0);
    const [totalRides, setTotalRides] = useState(0);
    const [trips, setTrips] = useState([]);
    const [error, setError] = useState(null);
    const email = decryptData('storageData')?.email;


    const totalTime = '22h33m';

    useEffect(() => {
        const fetchTrips = async () => {
            try {
                const response = await fetch(`/api/get-all-trips/${email}`);
                const data = await response.json();

                if (data.trips) {

                    const completedTrips = data.trips.filter(trip => trip.status === 'Completed');
                    const totalAmount = completedTrips.reduce((sum, trip) => sum + (trip.amount || 0), 0);
                    setTotalAmount(totalAmount);
                    setTotalRides(completedTrips.length);
                    setTrips(data.trips); // Assuming you have a state variable `trips`
                } else {
                    console.error("No trips found");
                    setTrips([]); // Fallback to an empty array
                }
            } catch (error) {
                console.error("Error fetching trips:", error);
                setTrips([]); // Ensure state is consistent
            }
        };

        fetchTrips();
    }, [email]);

    const handleOngoingTripClick = (tripReference) => {
        localStorage.setItem('tripReference', tripReference);
        navigate('/trip-progress');
    };

    const previousTrips = useMemo(() => trips.filter(trip => trip.status === 'Completed' || trip.status === 'Cancelled') , [trips]);
    const ongoingTrips = useMemo(() => trips.filter(trip => trip.status === 'Accepted' || trip.status === 'Driving to Pickup' || trip.status === 'Driving to Destination'), [trips]);
    const upcomingTrips = useMemo(() => trips.filter(trip => trip.status === 'Pending' || trip.status === 'Not Started'), [trips]);

    return (
        <DriverPage>
            <Helmet>
                <title>Driver Dashboard | Storage Sprint</title>
            </Helmet>
            <div className="ride-summary">
                <div className="ride-summary-scroll">
                    {error && <p className="error-message">Error: {error}</p>}
                    {/* Summary Card */}
                    <div className="summary-card">
                        <h2>Today</h2>
                        <h1>R{totalAmount.toFixed(2)}</h1>
                        <div className="summary-details">
                            <span>{totalRides} rides</span>
                            <span>{totalTime}</span>
                        </div>
                    </div>

                    {/* Ongoing Trips */}
                    <div className="ongoing-section">
                        <div className="ongoing-card">
                            <div className="ongoing-header">
                                <span>Ongoing</span>
                                <button className="navigation-button" disabled={true}>navigation</button>
                            </div>
                            {ongoingTrips.length > 0 ? (
                                ongoingTrips.map((trip, index) => (
                                    <div className="ride-details" key={index}
                                         onClick={() => handleOngoingTripClick(trip.reference)}>
                                        <p className="ride-person">{trip.customerName}</p>
                                        <p className="ride-route">
                                            From: {trip.pickupLocation} <br/>
                                            To: {trip.dropoffLocation}
                                        </p>
                                        <p className="ride-price">R{trip.amount.toFixed(2)}</p>
                                    </div>
                                ))
                            ) : (
                                <p>No ongoing trips</p>
                            )}
                        </div>

                        {/* Upcoming Trips */}
                        {upcomingTrips.length > 0 && (
                            <div className="upcoming-card" onClick={() => navigate('/available-trips')}>
                                <p>Upcoming({upcomingTrips.length})</p>
                                <span className="arrow">&gt;</span>
                            </div>
                        )}
                    </div>

                    {/* Previous Trips */}
                    <div className="previous-section">
                        <h3>Previous Trips</h3>
                        {previousTrips.length > 0 ? (
                            previousTrips.map((trip, index) => (
                                <div className="previous-trip" key={index}>
                                    <p className="ride-person">{trip.customerName}</p>
                                    <p className="ride-route">
                                        From: {trip.pickupLocation} <br />
                                        To: {trip.dropoffLocation}
                                    </p>
                                    <p className="ride-price">R{trip.amount.toFixed(2)}</p>
                                    <hr className="trip-divider" />
                                </div>
                            ))
                        ) : (
                            <p>No previous trips</p>
                        )}
                    </div>
                </div>
            </div>
        </DriverPage>
    );
};


export default RideSummary;