import React from "react";
import { useNavigate } from "react-router-dom";
import "../static/css/RegistrationSelectionPage.css";
import truckImage from "../static/images/image1.png";
import logo from "../static/images/ss-black.png";
import { Helmet } from 'react-helmet';

const RegistrationSelectionPage = () => {
  const navigate = useNavigate();

  const handleNavigate = (accountType) => {
    navigate(`/registration/${accountType}`);
  };

  const handleLoginClick = () => {
    navigate("/");
  }

  return (
    <>
      <Helmet>
        <title>Select Account Type - Storage Sprint</title>
      </Helmet>

      <div className="container">
        <div className="left-side">
          <img className="logo" src={logo} alt="Storage Sprint Logo" />
          <img className="truck" src={truckImage} alt="Storage Sprint Truck" />
          <p className="slogan">Schedule Now for Fast, Easy Delivery!</p> {/* Removed quotes here */}
        </div>

        <div className="right-side">
          <div className="select-account-container">
            <h2>Select Account Type</h2>
            <p className="select-account-message">Please select the type of account you want to create:</p>
            <button className="select-account-button" onClick={() => handleNavigate('personal')}>Customer Account</button>
            <button className="select-account-button" onClick={() => handleNavigate('business')}>Driver Account</button>
            <p className="switch-auth">
              Already have an account? <span onClick={handleLoginClick}>Login</span>
            </p>
          </div>
        </div>
      </div>
    </>
  );
};

export default RegistrationSelectionPage;
