import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom';
import { ToastContainer } from 'react-toastify'; // Import ToastContainer
import 'react-toastify/dist/ReactToastify.css'; // Import styles for toast notifications

import VerifyPayment from './paymentGateway/templates/verifyPayment';
import TripProgress from './driverDashboard/template/TripProgress';
import ContentUnavailable from './unavailable';
import MasterPage from './homeDashboard/templates/CustomerPage';
import TripDetails from './driverDashboard/template/TripDetails';

import { 
  AuthVerify, 
  RegisterCustomer,
  RegisterDriver, 
  Login, 
  UserProfile, 
  ForgotPassword, 
  ResetPassword, 
  HomeDashboard, 
  Transact , 
  Chat, 
  Support, 
  SelectionPage,
  Package,
  ConfirmTrip,
  Notification,
  Earnings,
  LandingPage,
  AboutUs,
  RideSummary,
  TermsAndCondtions,
  Privacy
} from './App';

import { decryptData } from "./utils/encryption";

const isAuthenticated = () => {
  const token = decryptData('storageData')?.authToken;
  return token ? true : false;
};

const PrivateRoute = ({ element }) => {
  return isAuthenticated() ? element : <Navigate to="/" />;
};

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <BrowserRouter>
    <ToastContainer /> {/* ToastContainer added here for global notifications */}
    <Routes>
      <Route path="/login" element={<Login />} />
      <Route path="/auth-verify/" element={<AuthVerify />} />
      <Route path="/selection/" element={<SelectionPage />} />
      <Route path="/registration/personal/" element={<RegisterCustomer />} />
      <Route path="/registration/business/" element={<RegisterDriver />} />
      <Route path="/forgot-password/" element={<ForgotPassword />} />
      <Route path="/reset-password/:uid/:token" element={<ResetPassword />} />

      {/* Private Routes */}
      <Route path="/pay/" element={<PrivateRoute element={<Transact />} />} />
      <Route path="/profile/" element={<PrivateRoute element={<UserProfile />} />} />
      <Route path="/dashboard/" element={<PrivateRoute element={<HomeDashboard />} />} />
      <Route path="/package/" element={<PrivateRoute element={<Package />} />} />

      {/* Landing Pages */}
      <Route path="/" element={<LandingPage />} />
      <Route path="/about-us/" element={<AboutUs />} />
      <Route path="/terms-and-conditions/" element={<TermsAndCondtions />} />
      <Route path="/privacy-policy/" element={<Privacy />} />

      {/* Support Routes */}
      <Route path="/support/" element={<PrivateRoute element={<Support />} />} />
      <Route path="/chat/" element={<PrivateRoute element={<Chat />} />} />
      <Route path="/confirm-trip" element={<PrivateRoute element={<ConfirmTrip />} />} />

      {/* Notification Routes */}
      <Route path="/notifications/" element={<PrivateRoute element={<Notification />} />} />

      {/* Payment Routes */}
      <Route path="/verify-payment/" element={<PrivateRoute element={<VerifyPayment />} />} />
      <Route path="/transact/" element={<PrivateRoute element={<Transact />} />} />

      {/* Driver Dashboard Routes */}
      <Route path="/driver-dashboard/" element={<PrivateRoute element={<RideSummary />} />} />
      {/*<Route path="/Ride-Summary/" element={<PrivateRoute element={<RideSummary />} />} />*/}
      <Route path="/trip-progress/" element={<PrivateRoute element={<TripProgress />} />} />
      <Route path="/available-trips/" element={<PrivateRoute element={<TripDetails />} />} />
      <Route path="/earnings/" element={<PrivateRoute element={<Earnings />} />} />

      {/* Catch-all undefined routes */}
      <Route path="*" element={<ContentUnavailable />} />
    </Routes>
  </BrowserRouter>
);
