import React from "react";
import NavBar from "./NavBar";
import "../static/About.css"
import Logo from '../../logos/storage-sprint.png';
import {Helmet} from "react-helmet";

const About = () => {
  
    return (
        <div>
          <Helmet>
                <title>About Us | Storage Sprint</title> 
        </Helmet>
        <NavBar/>
        <section className="About-container">
          <h2 className="About-title">About Company</h2>
        <div className="About-image">
            <img className= "logo" src={Logo}
            alt={Logo}/>
        </div>
      <div className="About-section">
        <h2>Revolutionizing On-Demand Storage Solutions </h2>
        <p>Storage Sprint is a pioneering on-demand storage service based in South Africa, 
        designed to meet the evolving needs of individuals and businesses seeking flexible, 
        accessible, and reliable storage solutions. Recognizing the challenges people face with 
        conventional storage, limited accessibility, inflexible pricing, and a lack of immediate 
        control over service options. Storage Sprint introduces a revolutionary, digital driven 
        service model that places control directly in customers' hands by introducing an 
        application that will allow customers to request moving services, book storage and 
        arrange collection at one go.</p>
      </div>
      <div className="About-section">
        <div>
          <h2>Vision</h2>
          <p>
          Our vision is to transform storage access by providing fast, reliable, and 
          accessible services that empower individuals and businesses to manage their 
          storage needs with unmatched convenience. We see a future where every 
          person, from students to small business owners, can securely and affordably 
          store and transport belongings with ease and at one go
          </p>
        </div>
        <div>
          <h2>Mission</h2>
          <p>
          Delivering Flexible Storage at Your Fingertips 
          Storage Sprint is dedicated to delivering efficient, cost-effective, and scalable 
          storage solutions through innovative technology, simplifying how people store 
          and move their belongings.  
          </p>
        </div>
        <div>
          <h2>Objectives</h2>
          <ul className="About-objectives">
             <li><h3>Customer Convenience</h3> </li>
             <p>We strive to create a seamless, user-friendly platform that allows customers to 
              request and manage storage services on demand. Our app customizes services 
              based on unique customer needs, with flexible options for product description, 
              distance, and quantity, making storage accessible anytime, anywhere.
              </p>
            <li><h3>Affordable Pricing</h3> </li>
            <p>Storage Sprint employs a transparent pricing model, combining a cost-plus 
              markup with fair adjustments based on distance and demand. Our goal is to 
              keep services competitively priced and accessible, meeting customer needs 
              while adapting to market fluctuations. 
            </p>
            <li><h3>Reliability and Trust</h3></li>
            <p>Reliability is foundational to Storage Sprint. Security measures ensure that both 
            individuals and businesses can trust us with their belongings. From secure 
            storage locations to dependable transport, we are committed to creating a 
            trusted, worry-free experience.
             </p>
             <li><h3>Sustainable Growth</h3></li>
            <p>Our ambition extends beyond local operations. Storage Sprint is poised to 
              expand across multiple regions in South Africa, Africa and the global society, 
              seeking strategic partnerships that will help scale our service while upholding 
              quality. By embedding ourselves in local markets, we aim to positively impact 
              communities while growing sustainably. 
             </p>
             <li><h3>Technological Innovation</h3></li>
            <p>Technology is at the heart of our business. Through continuous improvements 
              based on customer feedback, we are dedicated to enhancing the app’s 
              functionality. By streamlining service delivery, we offer a high-quality user 
              experience and an efficient, on-demand service that sets a new standard in the 
              storage industry.
             </p>
          </ul>
      </div>
      </div>
    </section>
        </div>
        );
};

export default About;