import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Helmet } from "react-helmet";
import DriverPage from "./DriverPage";
import { encryptData, decryptData } from "../../utils/encryption";
import "../static/css/tripDetails.css";
import "@fortawesome/fontawesome-free/css/all.min.css";


const email = decryptData("storageData")?.email;

const TripDetails = () => {
  const [trips, setTrips] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const [isActionInProgress, setIsActionInProgress] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchTrips = async () => {
      try {
        const response = await fetch(`/api/available-trip/${email}/`, {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            credentials: "include",

          },
        });
        const data = await response.json();

        if (data.trips && Array.isArray(data.trips)) {
          setTrips(data.trips);
        } else if (data.error) {
          setError(data.error);
        } else {
          setError("No trips available at the moment.");
        }
      } catch (error) {
        setError("Error fetching trips.");
      } finally {
        setIsLoading(false);
      }
    };

    fetchTrips();
  }, []);

  const handleAccept = async (reference) => {
    setIsActionInProgress(true);
    const optimisticTrips = trips.filter((trip) => trip.reference !== reference);
    setTrips(optimisticTrips);

    try {
      const response = await fetch(`/api/accept-trip/`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          credentials: "include",
        },
        body: JSON.stringify({ email, reference }),
      });

      const data = await response.json();

      console.log("Response data:", data.tripInfo);

      if (response.ok) {
        toast.success("Trip accepted successfully");
        encryptData("tripdata", data.tripInfo);
        localStorage.setItem("tripDetails",data.tripInfo);
        localStorage.setItem("tripReference", reference);
        navigate(`/trip-progress`);
      } else {
        setTrips([...optimisticTrips, { reference, ...data }]);
        setError(data.error || "Failed to accept the trip.");
      }
    } catch (error) {
      setTrips([...optimisticTrips, { reference, error }]);
      setError("Error accepting the trip.");
    } finally {
      setIsActionInProgress(false);
    }
  };

  const handleRejectTrip = async (reference) => {
    const optimisticTrips = trips.filter((trip) => trip.reference !== reference);
    setTrips(optimisticTrips);

    try {
      const response = await fetch(`/api/reject-trip/`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          credentials: "include",
        },
        body: JSON.stringify({ email, reference }),
      });
      const data = await response.json();
      console.log("Response data:", data);

      if (data.message) {
        toast.success("Trip rejected successfully");
      } else {
        setTrips([...optimisticTrips, { reference, error: "Failed to reject the trip" }]);
      }
    } catch (error) {
      setTrips([...optimisticTrips, { reference, error: "Error rejecting trip" }]);
      toast.error("Error rejecting trip. Please try again.");
    }
  };

  if (isLoading) {
    return (
      <DriverPage>
        <div className="loading-container">
          <div className="spinner"></div>
        </div>
      </DriverPage>
    );
  }

  if (error) {
    return (
      <DriverPage>
        <div className="error-container">
          <p className="error-message">{error}</p>
        </div>
      </DriverPage>
    );
  }

  if (trips.length === 0) {
    return (
      <DriverPage>
        <Helmet>
          <title>Available Trips | Storage Sprint</title>
        </Helmet>
        <div className="no-trips-container">
          <p className="no-trips-message">No available trips at the moment.</p>
        </div>
      </DriverPage>
    );
  }

  return (
    <DriverPage>
      <Helmet>
        <title>Available Trips | Storage Sprint</title>
      </Helmet>
      <div className="trip-details-container">
        <h1 className="title">Available Trips</h1>
        <p className="subtitle">
          Browse through our available trips below and choose the one that suits you best.
        </p>
        <div className="trip-grid">
          {trips.map((trip) => (
            <div key={trip.reference} className="trip-card">
             <div className="trip-info">
            <p>
              <i className="fas fa-dollar-sign"></i> <strong>Price:</strong> R{trip.price}
            </p>
            <p>
              <i className="fas fa-road"></i> <strong>Distance:</strong> {trip.distance} km
            </p>
            <p>
              <i className="fas fa-map-marker-alt"></i> <strong>Pickup Location:</strong>{" "}
              {trip.pickupLocation}
            </p>
            <p>
              <i className="fas fa-map-pin"></i> <strong>Dropoff Location:</strong>{" "}
              {trip.dropoffLocation}
            </p>
          </div>
          <div className="trip-actions">
            <button
              className={`accept-button ${isActionInProgress ? "disabled" : ""}`}
              disabled={isActionInProgress}
              onClick={() => handleAccept(trip.reference)}
            >
              <i className="fas fa-check-circle"></i> {isActionInProgress ? "Processing..." : "Accept"}
            </button>
            <button className="decline-button" onClick={() => handleRejectTrip(trip.reference)}>
              <i className="fas fa-times-circle"></i> Decline
            </button>
          </div>

            </div>
          ))}
        </div>
      </div>
      <ToastContainer />
    </DriverPage>
  );
};

export default TripDetails;
