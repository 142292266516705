import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import Logo from '../../logos/storage-sprint.png';
import '../static/css/Login.css';
// import Logo from '../static/images/ss-original.png';
import { encryptData } from '../../utils/encryption'; 
import { BiSolidHide, BiSolidShow } from "react-icons/bi";

const LoginPage = () => {
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [showPassword, setShowPassword] = useState(false);
    const [error, setError] = useState('');

    const [logoLoaded, setLogoLoaded] = useState(false);

    const handleError = (error) => {
        if (typeof error === 'string') {
            setError(error);
        } else if (error && typeof error.text === 'string') {
            setError(error.text);
        } else {
            setError('An unknown error occurred.');
        }
    };

    const navigate = useNavigate();

    const handleLogin = async () => {
        setError('');

        if (!username || !password) {
            setError('Email and password are required.');
            return;
        }

        try {
            const response = await fetch('/api/login/', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                credentials: "include",
                body: JSON.stringify({ username, password }),
            });

            const data = await response.json();
            if (response.ok) {
                if (data.token) {
                    const dataSprint = {
                        authToken: data.token,
                        firstName: data.user.firstName,
                        lastName: data.user.lastName,
                        email: data.user.email,
                        role: data.user.role,
                        PhoneNumber: data.user.phoneNumber,
                        title: data.user.title,
                    };

                    encryptData("storageData", dataSprint);

                    if (data.user.role === 'driver' || data.user.role === 'admin') {
                        navigate('/driver-dashboard');
                    } else if (data.user.role === 'user') {
                        navigate('/dashboard');
                    }
                } else {
                    handleError('Unexpected response format.');
                }
            } else {
                handleError(data.message || 'Incorrect email or password.');
            }
        } catch (error) {
            handleError('An error occurred while submitting your request.');
        }
    };

    return (
        <>
            <Helmet>
                <title>Sign In | Storage Sprint</title>
                <meta name="description" content="Delivering Flexible Storage at Your Fingertips. Storage Sprint is dedicated to delivering efficient, cost-effective, and scalable storage solutions through innovative technology, simplifying how people store and move their belongings." />

                {/* Open Graph Meta Tags */}
                <meta property="og:title" content="Storage Sprint" />
                <meta property="og:description" content="Delivering Flexible Storage at Your Fingertips. Storage Sprint is dedicated to delivering efficient, cost-effective, and scalable storage solutions through innovative technology, simplifying how people store and move their belongings." />
                <meta property="og:image" content="/static/images/ss-original.png" /> {/* Update with the correct image path */}
                <meta property="og:url" content="https://storagesprint.co.za" />
                <meta property="og:type" content="website" />

                {/* Twitter Meta Tags */}
                <meta name="twitter:card" content="summary_large_image" />
                <meta name="twitter:title" content="Storage Sprint" />
                <meta name="twitter:description" content="Delivering Flexible Storage at Your Fingertips. Storage Sprint is dedicated to delivering efficient, cost-effective, and scalable storage solutions through innovative technology, simplifying how people store and move their belongings." />
                <meta name="twitter:image" content="/static/images/ss-original.png" />
            </Helmet>

            <div className="login-body">

                    <div className="main-container">

                        {/* Logo and Slogan */}
                        <div className='brand-container'>
                            
                        {!logoLoaded && <div className="logo-placeholder">Loading Logo...</div>}
                        <img
                            src={Logo}
                            alt="Logo"
                            className={`login-logo ${logoLoaded ? 'visible' : 'hidden'}`}
                            onLoad={() => setLogoLoaded(true)}
                        />
                            <h2 className="slogan">SCHEDULE YOUR DELIVERY NOW!</h2>
                        </div>

                        {/* Login Form */}
                        <div className="login-form-container">

                            <p>Login to Storage Sprint</p>
                            <div className="login-form">
                                <input
                                    id="email"
                                    type="email"
                                    value={username}
                                    placeholder="Enter your email"
                                    onChange={(e) => setUsername(e.target.value)}
                                />

                                

                                {/* Password input with eye icon */}
                                <div className="password-container">
                                    <input
                                        id="password"
                                        type={showPassword ? 'text' : 'password'}
                                        value={password}
                                        placeholder="Enter your password"
                                        onChange={(e) => setPassword(e.target.value)}
                                    />
                                    <span
                                        className="password-icon"
                                        onClick={() => setShowPassword(!showPassword)}
                                    >
                                        {showPassword ? <BiSolidHide /> : <BiSolidShow />}
                                    </span>
                                </div>

                                <button onClick={handleLogin}>Continue</button>
                                {error && <div className="error">{error}</div>}

                                {/* Links */}
                                <div className="links">
                                    <a href="/forgot-password/">Forgot password?</a>
                                    <a href="/selection/">Create Account</a>
                                </div>
                                
                            </div>
                        </div>
                        
                    </div>


                    {/* Footer */}
                    <footer className="login-footer">
                        <div className="footer-Content">
                            <div className="footer-links">
                                <a href="/about-us">About Us</a>
                                <a href="/privacy-policy">Privacy Policy</a>
                                <a href="/terms-and-conditions">Terms & Conditions</a>
                            </div>
                            <div className="footer-copyright">
                                <p>&copy; {new Date().getFullYear()} Storage Sprint. All rights reserved.</p>
                            </div>
                        </div>
                    </footer>

            </div>
        </>
    );
};

export default LoginPage;