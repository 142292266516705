import React from 'react';
import '../about/static/about.css'; // Import a CSS file for styling
import logo from '../authentication/static/images/ss-original.png'; // Update the path as necessary
import { useNavigate } from 'react-router-dom';
import { Helmet } from 'react-helmet';

const AboutUs = () => {
    const navigate = useNavigate();

    const handleBackClick = () => {
        navigate('/'); // Navigate back to the landing page
    };

    return (
        <>
        <Helmet> Storage Sprint | About Us </Helmet>
        <div className="about-us">
            <img src={logo} alt="Storage Sprint Logo" className="logo" />
            <h1>About Storage Sprint</h1>

            <div className="vision-mission">
                <div className="vision">
                    <h2>Vision</h2>
                    <p>
                        Revolutionizing On-Demand Storage Solutions. Our vision is to transform storage access by providing fast, reliable, and accessible services that empower individuals and businesses to manage their storage needs with unmatched convenience.
                    </p>
                </div>
                <div className="mission">
                    <h2>Mission</h2>
                    <p>
                        Delivering Flexible Storage at Your Fingertips. Storage Sprint is dedicated to delivering efficient, cost-effective, and scalable storage solutions through innovative technology, simplifying how people store and move their belongings.
                    </p>
                </div>
            </div>

            <h2>Our Objectives</h2>
            <ul className="objectives">
                <li><strong>Customer Convenience:</strong> We strive to create a seamless, user-friendly platform where customers can request and manage storage services on demand, tailored to fit their unique needs.</li>
                <li><strong>Affordable Pricing:</strong> Our transparent pricing model combines a cost-plus markup with fair adjustments based on distance, weight, and demand, ensuring our services remain competitive and accessible.</li>
                <li><strong>Reliability and Trust:</strong> At Storage Sprint, security and dependability are paramount. We are committed to providing a secure service that both individuals and businesses can count on.</li>
                <li><strong>Sustainable Growth:</strong> We aim to expand Storage Sprint across multiple regions while maintaining quality and exploring local partnerships to enhance our reach and impact.</li>
                <li><strong>Technological Innovation:</strong> Driven by customer feedback, we continually improve our app to offer the best user experience, focusing on efficiency and streamlining service delivery.</li>
            </ul>

            <footer className="footer">
                <div className="footerContent">
                    <div className="footerLinks">
                        <a href="/">Home</a>
                        <a href="/about">About Us</a>
                        <a href="/contact">Contact</a>
                    </div>
                    <div className="copyright">© 2024 Storage Sprint. All rights reserved.</div>
                </div>
            </footer>
        </div>
            
        </>
    );
};

export default AboutUs;
