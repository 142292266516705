import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom'; 

import '../static/css/tripDetails.css'; 
import { Helmet } from 'react-helmet';
import '../static/css/tripDetails.css'; 
import DriverPage from './DriverPage';
import { encryptData, decryptData } from '../../utils/encryption'; 

const email = decryptData('storageData')?.email;

const TripDetails = () => {
  const [trips, setTrips] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const [isActionInProgress, setIsActionInProgress] = useState(false);
  
  const navigate = useNavigate();

  
  useEffect(() => {
    
  }, [trips]);

  useEffect(() => {
    document.title = "Available Notification";
  }, []);

  useEffect(() => {
    const fetchTrips = async () => {
      try {
        const response = await fetch('/api/available-trip/');
        const data = await response.json();


         if (data.trips && Array.isArray(data.trips)) {
        setTrips(data.trips); // Set trips from response
      } else if (data.error) {
        setError(data.error); // Set error message
        setTrips([]); // Clear any previous trips
      } else {
        setError('No trips available at the moment.');
        setTrips([]);
      }

      setIsLoading(false);
    } catch (error) {

      setError('No trips available at the moment.');
      setIsLoading(false);
    }
  };

  fetchTrips();
}, []);

  const handleAccept = async (reference) => {
    setIsActionInProgress(true);
    try {
      const response = await fetch(`/api/accept-trip/`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'credentials': 'include'
        },
        body: JSON.stringify({ email, reference }),
      });

      const data = await response.json();

      if (response.ok) {
        setTrips((prevTrips) => prevTrips.filter((trip) => trip.reference !== reference));
        encryptData('tripdata', reference);
        navigate(`/trip-progress/${reference}`);
      } else {
        setError(data.error || 'Failed to accept the trip.');
      }
    } catch (error) {
      console.error('Error accepting trip:', error);
      setError('Error accepting the trip.');
    } finally {
      setIsActionInProgress(false);
    }
  };
  
  const handleRejectTrip = async (reference) => {
    try {
      const response = await fetch(`/api/reject-trip/`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'credentials': 'include'
        },
        body: JSON.stringify({ email, reference }),
      });
      const data = await response.json();
      if (data.message) {
        alert('Trip rejected successfully');
      }
    } catch (error) {
      console.error('Error rejecting trip:', error);
      alert('Failed to reject the trip.');
    }
  };

  if (isLoading) {
    return (
      <DriverPage>
        <div className="spinner"></div> {/* Modern spinner */}
      </DriverPage>
    );
  }

  if (error) {
    return (
      <DriverPage>
        <div className="error-message">
          <p>{error}</p>
        </div>
      </DriverPage>
    );
  }

  if (trips.length === 0) {
    return (
      <DriverPage>
        <div className="no-trip-message">
          <p>No available trips at the moment.</p>
        </div>
      </DriverPage>
    );
  }

  return (
    <DriverPage>
      <Helmet>
        <title>Available Trips</title> 
      </Helmet>
      <h1 style={{ textAlign: 'center' }}>Available Trips</h1>
      <p style={{ textAlign: 'center' }}>Browse through our available trips below and choose the one that suits you best.</p>
      <div className="trip-grid-container">
        {trips.map((trip) => (
          <div className="trip-card" key={trip.reference}>
            <h2 className="trip-title">Trip Details</h2>
            <div className="trip-info">
              <p><strong>Price:</strong> R{trip.price}</p>
              <p><strong>Distance:</strong> {trip.distance} km</p>
              <p><strong>Pickup Location:</strong> {trip.pickupLocation}</p>
              <p><strong>Dropoff Location:</strong> {trip.dropoffLocation}</p>
            </div>
            <div className="trip-actions">
              <button
                className="accept-button"
                disabled={isActionInProgress}
                onClick={() => handleAccept(trip.reference)}
              >
                {isActionInProgress ? 'Processing...' : 'Accept'}
              </button>
              <button
                className="decline-button"
                onClick={() => handleRejectTrip(trip.reference)}
              >
                Decline
              </button>
            </div>
          </div>
        ))}
      </div>
    </DriverPage>
  );
};

export default TripDetails;
