import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import "../static/css/RegistrationPage.css";
import truckImage from "../static/images/image1.png";
import logo from "../static/images/ss-black.png";
import { FaFacebook, FaTwitter } from "react-icons/fa";
import { BiSolidHide, BiSolidShow } from "react-icons/bi";
import { Helmet } from 'react-helmet';

const DriverRegistrationPage = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [registrationNumber, setRegistrationNumber] = useState("");
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [passwordError, setPasswordError] = useState("");

  const navigate = useNavigate();

  const MIN_LENGTH = 8;
  const UPPERCASE_REGEX = /[A-Z]/;
  const DIGIT_REGEX = /\d/;

  const validatePassword = (password) => {
    const errors = [];

    if (password.length < MIN_LENGTH) {
      errors.push(`Password should be at least ${MIN_LENGTH} characters long.`);
    }
    if (!UPPERCASE_REGEX.test(password)) {
      errors.push("Password should include at least one uppercase letter.");
    }
    if (!DIGIT_REGEX.test(password)) {
      errors.push("Password should include at least one number.");
    }

    setPasswordError(errors.join(" "));
  };

  const handlePasswordChange = (e) => {
    const newPassword = e.target.value;
    setPassword(newPassword);
    validatePassword(newPassword);
  };

  const handleLoginClick = () => {
    navigate('/');
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    setError(null);

    if (password !== confirmPassword) {
      setError("Passwords do not match.");
      setLoading(false);
      return;
    }

    const formData = {
      firstName,
      lastName,
      email,
      phoneNumber,
      registrationNumber,
      password,
      confirm_password: confirmPassword,
    };

    try {
      const response = await fetch("/api/registration/", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        credentials: 'include',
        body: JSON.stringify(formData),
      });

      if (!response.ok) {
        const jsonResponse = await response.json();
        throw new Error(`Error: ${jsonResponse.message || "An error occurred while submitting your request, please try again."}`);
      }

      const data = await response.json();
      console.log("Success:", data);
      navigate("/auth-verify/", { state: { email } });

    } catch (error) {
      console.error("Error:", error);
      setError(error.message);
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <Helmet>
        <title>Driver Registration - Storage Sprint</title>
      </Helmet>

      <div className="container">
        <div className="left-side">
          <img className="logo" src={logo} alt="Storage Sprint Logo" />
          <img className="truck" src={truckImage} alt="Storage Sprint Truck" />
          <p className="slogan">Schedule Now for Fast, Easy Delivery!</p> {/* Removed quotes */}
        </div>

        <div className="right-side">
          <div className="registration-container">
            <form onSubmit={handleSubmit} className="form-container">
              <h2>Driver Registration</h2>

              <div className="input-box">
                <input
                  type="text"
                  value={firstName}
                  onChange={(e) => setFirstName(e.target.value)}
                  placeholder="First Name"
                  required
                />
                <input
                  type="text"
                  value={lastName}
                  onChange={(e) => setLastName(e.target.value)}
                  placeholder="Last Name"
                  required
                />
              </div>

              <div className="input-box">
                <input
                  type="email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  placeholder="Email"
                  required
                />
                <input
                  type="tel"
                  value={phoneNumber}
                  onChange={(e) => setPhoneNumber(e.target.value)}
                  placeholder="Phone Number"
                  required
                />
              </div>

              <div className="input-box">
                <input
                  type="text"
                  value={registrationNumber}
                  onChange={(e) => setRegistrationNumber(e.target.value)}
                  placeholder="Vehicle Registration Number"
                  required
                />
              </div>

              <div className="input-box">
                {passwordError && <p className="error-message">{passwordError}</p>}
                <input
                  type="password"
                  value={password}
                  onChange={handlePasswordChange}
                  placeholder="Password"
                  required
                />
                <div className="confirm-password-wrapper">
                  <input
                    type={showPassword ? "text" : "password"}
                    value={confirmPassword}
                    onChange={(e) => setConfirmPassword(e.target.value)}
                    placeholder="Confirm Password"
                    required
                  />
                  <span
                    className="password-icon"
                    onClick={() => setShowPassword(!showPassword)}
                    aria-label={showPassword ? "Hide password" : "Show password"}
                  >
                    {showPassword ? <BiSolidHide /> : <BiSolidShow />}
                  </span>
                </div>
              </div>

              {loading && <p className="loading-message">Loading...</p>}
              {error && <p className="error-message">{error}</p>}

              <button className="form-button" type="submit" disabled={passwordError}>
                Register
              </button>

              <p className="switch-auth">
                Already have an account? <span onClick={handleLoginClick}>Login</span>
              </p>
            </form>

            <footer className="social-footer">
              <button className="social-login">
                <FaFacebook className="social-icon" />
                Sign Up with Facebook
              </button>
              <button className="social-login">
                <FaTwitter className="social-icon" />
                Sign Up with Twitter
              </button>
            </footer>
          </div>
        </div>
      </div>
    </>
  );
};

export default DriverRegistrationPage;
