import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom';
import VerifyPayment from './paymentGateway/templates/verifyPayment';
import DriverPage from './driverDashboard/template/DriverPage';
import TripProgress from './driverDashboard/template/TripProgress';
import ContentUnavailable from './unavailable';

import MasterPage from './homeDashboard/templates/CustomerPage';

// test master template
import TripDetails from './driverDashboard/template/TripDetails';

import { 
  AuthVerify, 
  RegisterCustomer,
  RegisterDriver, 
  Login, 
  UserProfile, 
  ForgotPassword, 
  ResetPassword, 
  HomeDashboard, 
  Transact , 
  Chat, 
  Support, 
  SelectionPage,
  Package,
  ConfirmTrip,
  Notification,
  TripDetail,
  Earnings,
  AboutUs
} from './App';

import {decryptData} from "./utils/encryption";


const isAuthenticated = () => {

  const token = decryptData('storageData')?.authToken;

  return token ? true : false;
};

const PrivateRoute = ({ element }) => {
  return isAuthenticated() ? element : <Navigate to="/" />;
};

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <BrowserRouter>
    <Routes>
      {/* ** Public Authentication Route (Always shows the Login component) ** */}
      <Route 
        path="/" 
        element={<Login />}
      />
      <Route path="/auth-verify/" element={<AuthVerify />} />
      <Route path="/selection/" element={<SelectionPage />} />
      <Route path="/registration/personal/" element={<RegisterCustomer />} />
      <Route path="/registration/business/" element={<RegisterDriver />} />
      <Route path="/forgot-password/" element={<ForgotPassword />} />
      <Route path="/reset-password/:uid/:token" element={<ResetPassword />} />

      {/* ** Private (Authenticated) Routes ** */}
      <Route path="/pay/" element={<PrivateRoute element={<Transact />} />} />
      <Route path="/profile/" element={<PrivateRoute element={<UserProfile />} />} />
      <Route path="/dashboard/" element={<PrivateRoute element={<HomeDashboard />} />} />
      <Route path="/package/" element={<PrivateRoute element={<Package />} />} />

      {/* Support routes */}
      <Route path="/support/" element={<PrivateRoute element={<Support />} />} />
      <Route path="/chat/" element={<PrivateRoute element={<Chat />} />} />
      <Route path="/confirm-trip" element={<PrivateRoute element={<ConfirmTrip />} />} />

      {/* ** Notification Routes ** */}
      <Route path="/notifications/" element={<PrivateRoute element={<Notification />} />}/>
      <Route path="/trip-Details/:tripId" element={<PrivateRoute element={<TripDetail/>} />} />


      {/* ** Payment Routes ** */}
      <Route path="/verify-payment/" element={<PrivateRoute element={<VerifyPayment />} />} />

      {/* ** Driver Dashboard Routes ** */}
      <Route path="/driver-dashboard/" element={<PrivateRoute element={<DriverPage />} />} />
      <Route path="/trip-progress/:tripReference" element={<PrivateRoute element={<TripProgress />} />} />
      <Route path="/available-trips/" element = {<PrivateRoute element={<TripDetails />} />}/>
      <Route path="/earnings/" element={<PrivateRoute element={<Earnings />} />} />

      {/* ** Test Routes ** */}
      <Route path="/test/" element={<PrivateRoute element={<MasterPage />} />} />
 

      {/* Catch all undefined routes */}
      <Route path="*" element={<ContentUnavailable />} />
      <Route path="/about" element={<AboutUs />} />
    </Routes>
  </BrowserRouter>
);
