import React from "react";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import "../static/css/RegistrationSelectionPage.css";
import truckImage from "../static/images/image1.png";
import logo from '../../logos/storage-sprint.png';
import { Helmet } from 'react-helmet';

const RegistrationSelectionPage = () => {
  const navigate = useNavigate();
  const [logoLoaded, setLogoLoaded] = useState(false);


  const handleNavigate = (accountType) => {
    navigate(`/registration/${accountType}`);
  };

  const handleLoginClick = () => {
    navigate("/login");
  }

  return (
    <>
      <Helmet>
        <title>Select Account Type - Storage Sprint</title>
      </Helmet>

      <div className="container">
        <div className="left-side">
          <img className="logo" src={logo} alt="Storage Sprint Logo" />
          <img className="truck" src={truckImage} alt="Storage Sprint Truck" />
          <h2 className="slogan">SCHEDULE YOUR DELIVERY NOW!</h2>
        </div>

        <div className="right-side">
          {/* Moved logo and slogan outside the select-account-container */}
          <div className='brand-container'>
                            
            {!logoLoaded && <div className="logo-placeholder">Loading Logo...</div>}
              <img
              src={logo}
              alt="Logo"
              className={`login-logo ${logoLoaded ? 'visible' : 'hidden'}`}
              onLoad={() => setLogoLoaded(true)}
              />
              <h2 className="slogan">SCHEDULE YOUR DELIVERY NOW!</h2>
          </div>

          <div className="select-account-container">
            <h3>SELECT ACCOUNT TYPE</h3>
            <button className="select-account-button" onClick={() => handleNavigate('personal')}>Customer Account</button>
            <button className="select-account-button" onClick={() => handleNavigate('business')}>Driver Account</button>
            <p className="switch-auth">
              Already have an account? <span onClick={handleLoginClick}>Login</span>
            </p>
          </div>
          <footer className="login-footer">
            <div className="footer-Content">
              <div className="footer-links">
                <a href="/about-us/">About Us</a>
                <a href="/privacy-policy/">Privacy Policy</a>
                <a href="/terms-and-conditions/">Terms & Conditions</a>
              </div>
              <div className="footer-copyright">
                <p>&copy; {new Date().getFullYear()} Storage Sprint. All rights reserved.</p>
              </div>
            </div>
          </footer>
        </div>
      </div>
    </>
  );
};

export default RegistrationSelectionPage;
