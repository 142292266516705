import React, { useState } from 'react';
import '../static/css/verificationPage.css';
import { Helmet } from 'react-helmet';
import { useLocation, useNavigate } from 'react-router-dom';

const Verification = () => {
  const location = useLocation();
  const navigate = useNavigate();  // Initialize useNavigate
  const userEmail = location.state?.email || '';
  const [activationCode, setActivationCode] = useState('');
  const [responseMessage, setResponseMessage] = useState(null);  // Default to null

  const handleActivationCodeChange = (event) => {
    setActivationCode(event.target.value);
  };

  const handleManualActivation = async (e) => {
    e.preventDefault();
    const formData = { activationCode };

    try {
      const response = await fetch('/api/auth-verify/', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        credentials: 'include',
        body: JSON.stringify(formData),
      });

      const data = await response.json();
      if (response.ok) {
        setResponseMessage({ type: 'success', text: data.message });
        navigate('/');  // Redirect to the desired route
      } else {
        setResponseMessage({ type: 'error', text: data.message || 'An error occurred while submitting your request.' });
      }
    } catch (error) {
      setResponseMessage({ type: 'error', text: 'An error occurred while submitting your request.' });
    }
  };

  const handleNewActivationLink = async (e) => {
    e.preventDefault();
    if (!userEmail) {
      setResponseMessage({ type: 'error', text: 'No email address provided.' });
      return;
    }
    try {
      const response = await fetch('/api/resend-code/', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        credentials: 'include',
        body: JSON.stringify({ email: userEmail }),
      });

      const data = await response.json();
      if (response.ok) {
        setResponseMessage({ type: 'success', text: data.message });
        navigate('/');  // Redirect to home page
      } else {
        setResponseMessage({ type: 'error', text: data.message || 'Failed to resend activation code.' });
      }
    } catch (error) {
      setResponseMessage({ type: 'error', text: 'An error occurred while submitting your request.' });
    }
  };

  const handleEmailRetry = () => {
    window.location.href = '/registration/';
  };

  return (
    <>
      <Helmet>
        <title>Verify Your Email | Storage Sprint</title>
      </Helmet>
      <div className="verification-page">
        <div className="verification-container">
          <h1>Hi! And welcome to Storage Sprint!</h1>
          <p>
            We have sent a verification email to <strong>{userEmail}</strong> with an activation code..
          </p>
          <div className="activation-section">
            {responseMessage && (
              <p className={`response-message ${responseMessage.type}`} aria-live="polite" style={{ color:'white' }}>
                {responseMessage.text}
              </p>
            )}
            <div className="manual-activation">
              <div className="input-group">
                <input
                  type="text"
                  placeholder="Type your activation code here..."
                  value={activationCode}
                  onChange={handleActivationCodeChange}
                  className="input"
                />
                <button onClick={handleManualActivation} className="button activate-button">
                  Activate
                </button>
                <button onClick={handleNewActivationLink} className="button new-link-button">
                  Request New Code
                </button>
              </div>
            </div>
            <div className="email-retry">
              <h2>Wrong email address?</h2>
              <p>Well, no worries: click the button below to go back to the registration page and edit your email address.</p>
              <button onClick={handleEmailRetry} className="button retry-button">
                Edit Email Address
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Verification;
