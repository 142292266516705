import Verification from './authentication/templates/authVerification';
import CustomerRegistrationPage from './authentication/templates/RegistrationPagePersonal';
import DriverRegistrationPage from './authentication/templates/RegistrationPageBusiness';
import RegistrationSelectionPage from './authentication/templates/registrationSelectionPage';
import LoginPage from './authentication/templates/Login';
import UserProfilePage from './userProfile/userProfile';
import SupportPage from './Support/templates/supportPage';
import StartChat from './Support/templates/chatPage';
import ForgotPasswordPage from './authentication/templates/ForgotPassword';
import ResetPasswordPage from './authentication/templates/ResetPassword';
import HomeDashboard from './homeDashboard/templates/homeDashboard';
import Transaction from './paymentGateway/templates/Transaction';
import PackagePage from './homeDashboard/templates/PackageDescription';
import ConfirmTrip from './homeDashboard/templates/confirmTrip';
import Notification from './notifications/templates/Notification'
import DriverPage from './driverDashboard/template/DriverPage';
import TripProgress from './driverDashboard/template/TripProgress';
import ContentUnavailable from './unavailable';
import MasterPage from './homeDashboard/templates/CustomerPage';
import Earnings from './driverDashboard/template/Earnings';
import TripDetails from './driverDashboard/template/TripDetails';
import RideSummary from "./driverDashboard/template/RideSummary";
import LandingPage from './landingPage/templates/Home';
import About from './landingPage/templates/About';
import TermsAndCondtions from './landingPage/templates/TermsAndConditions';
import Privacy from './landingPage/templates/Privacy';


export { 
  Verification as AuthVerify,
  CustomerRegistrationPage as RegisterCustomer,
  DriverRegistrationPage as RegisterDriver,
  LoginPage as Login,
  HomeDashboard,
  UserProfilePage as UserProfile,
  SupportPage as Support,
  StartChat as Chat,
  ForgotPasswordPage as ForgotPassword,
  ResetPasswordPage as ResetPassword,
  RegistrationSelectionPage as SelectionPage,
  Transaction as Transact,
  PackagePage as Package,
  ConfirmTrip,
  DriverPage,
  TripProgress,
  ContentUnavailable,
  MasterPage,
  Earnings,
  TripDetails,
  Notification,
  LandingPage,
  TermsAndCondtions,
  RideSummary,
  About as AboutUs,
  Privacy
};
