import React, { useState , useEffect} from 'react';
import { PaystackButton } from 'react-paystack';
import {v4 as uuidv4} from 'uuid'
import '../static/css/Transaction.css';

const Transaction = () => {
  const publicKey = "pk_test_bd9178e45447a98147e2c022acaed49998b53e8a";
  const email = useState(localStorage.getItem('email'));
  const [amount] = useState(100);
  const name = useState(localStorage.getItem('firstName'));
  const phone = useState(localStorage.getItem('phoneNumber'));
  const [currency] = useState("ZAR")
  const [reference, setReference] = useState("")

  useEffect(() => {
    const generateUniqueReference = async () => {
      let unique = false;
      let newReference;

      while (!unique) {
        newReference = `spr-${uuidv4()}`;
        try {
          const response = await fetch('api/check-reference/', {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify({ reference: newReference }),
          });

          const data = await response.json();
          if (data.isUnique) {
            unique = true;
            setReference(newReference);  // Set the reference state
          }
        } catch (error) {
          console.error('Error checking reference:', error);
          unique = false;
        }
      }
    };

    generateUniqueReference();
  }, []);

  const componentProps = {
    email: email,
    amount: amount * 100,
    currency, // Ensure this is a supported currency
    reference: reference,
    metadata: {
      name: name,
      phone: name,
    },
    publicKey,
    text: "Pay",
    onSuccess:() => handlePaymentSuccess(),
    onClose: () => alert("Payment Cancelled!!"),
  };

  const handlePaymentSuccess = async () => {
    
    try {
      const response = await fetch('api/store-transaction/', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          reference: reference,
          email: email,
          amount: amount, 
          currency: currency, 
          name: name,
          phone: phone,
        }),
      });
  
      const data = await response.json();
      if (response.ok) {
        alert('Transaction stored successfully');
      } else {
        alert('Failed to store transaction: ' + data.message);
      }
    } catch (error) {
      console.error('Error:', error);
      alert('An error occurred while storing the transaction.');
    }
  };

  return (

    <div className="transaction-body">
      <div className="transaction-container">
        <div className="transaction-form-container">
          {/* <h1 className="transaction-header">Make your payment here</h1> */}
          <div className="transaction-form">
            {/* <h3>Pay: ZAR {amount}</h3>
            <input
              type="email"
              placeholder="Email"
              className="transaction-input"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
            />
            <input
              type="text"
              placeholder="Name"
              className="transaction-input"
              value={name}
              onChange={(e) => setName(e.target.value)}
              required
            />
            <input
              type="number"
              placeholder="Phone number"
              className="transaction-input"
              value={phone}
              onChange={(e) => setPhone(e.target.value)}
              required
            /> */}
            <PaystackButton  className="transaction-button" {...componentProps} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Transaction;
