import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import DeliveryPic from "../images/hero-bg.jpg";
import "../static/Home.css"; 
import Footer from "./Footer";
import NavBar from "./NavBar";
import { Helmet } from "react-helmet";
import { FaPhoneAlt } from "react-icons/fa";
import { MdOutlineMail } from "react-icons/md";

const Home = () => {
  // For Contact Form
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");
  const [status, setStatus] = useState("idle");

  const location = useLocation();
  const navigate = useNavigate();

  // When coming from another route with a state to scroll to a section
  useEffect(() => {
    if (location.state?.scrollTo) {
      const section = document.getElementById(location.state.scrollTo);
      if (section) {
        section.scrollIntoView({ behavior: "smooth" });
      }
    }
  }, [location.state]);

  const handleStartNowClick = () => {
    navigate("/selection");
  };

  const handleSendClick = () => {
    setStatus("waiting");
    setTimeout(() => {
      setStatus("idle");
      // Optionally clear form fields or show a success message here
    }, 2000);
  };

  return (
    <div className="Home">
      <Helmet>
        <title>Home | Storage Sprint</title>
      </Helmet>
      <NavBar />

      {/* ===== Home Section ===== */}
      <section
        id="home-section"
        className="home-section"
        style={{ backgroundImage: `url(${DeliveryPic})` }}
      >
        <div className="home-overlay"></div>
        <div className="home-content">
          <h1>
            Welcome to <span>Storage Sprint</span>
          </h1>
          <p>
            Your reliable partner for hassle-free deliveries. From storage to
            doorstep delivery, we've got you covered!
          </p>
          <button onClick={handleStartNowClick}>Start Now</button>
        </div>
      </section>

      {/* ===== Services Section ===== */}
      <section id="services-section" className="services-section">
        <div className="services-container">
          <h2 className="section-title">Our Services</h2>
          <p className="section-subtitle">
            At Storage Sprint, we specialize in seamless delivery solutions
            tailored to your needs.
          </p>
          <div className="services-content">
            <div className="services-item">
              <h3>Real-Time Storage Solutions</h3>
              <ul className="services-info">
                <li>
                  Immediate assistance for short-term or emergency storage.
                </li>
                <li>
                  Convenient and accessible storage options on demand.
                </li>
              </ul>
            </div>
            <div className="services-item">
              <h3>Large-Item Delivery Services</h3>
              <ul className="services-info">
                <li>
                  Transportation of bulky items like furniture and appliances.
                </li>
                <li>
                  Accessible delivery for large item purchases.
                </li>
              </ul>
            </div>
            <div className="services-item">
              <h3>Short-Distance Courier Services</h3>
              <ul className="services-info">
                <li>
                  Specialized in short-distance deliveries to fill market gaps.
                </li>
                <li>Flexible options for weight and quantity.</li>
              </ul>
            </div>
            <div className="services-item">
              <h3>Support for Movers</h3>
              <ul className="services-info">
                <li>
                  Assistance during relocations for individuals and businesses.
                </li>
                <li>
                  Efficient handling of packing, transport, and temporary storage.
                </li>
              </ul>
            </div>
          </div>
          <div className="services-reasons">
            <h3>Why Choose Storage Sprint?</h3>
            <p>
              <strong>Reliable &amp; Professional</strong>: A team you can trust
              with your belongings.
            </p>
            <p>
              <strong>Affordable Pricing</strong>: High-quality service at
              competitive rates.
            </p>
            <p>
              <strong>Customer-Centric</strong>: Your satisfaction is our
              priority.
            </p>
          </div>
        </div>
      </section>

      {/* ===== About Us Section ===== */}
      <section id="aboutUs-section" className="about-section">
        <div className="about-container">
          <h2>Revolutionizing On-Demand Storage Solutions</h2>
          <p>
            Storage Sprint is a pioneering on-demand storage service based in South
            Africa, designed to meet the evolving needs of individuals and businesses
            seeking flexible, accessible, and reliable storage solutions.
          </p>
        </div>
        <div className="about-container">
          <div className="about-block">
            <h2>Vision</h2>
            <p>
              We envision transforming storage access by offering fast, reliable,
              and accessible services that empower our customers.
            </p>
          </div>
          <div className="about-block">
            <h2>Mission</h2>
            <p>
              Delivering Flexible Storage at Your Fingertips. We commit to efficient,
              cost-effective, and scalable storage solutions.
            </p>
          </div>
          <div className="about-block">
            <h2>Objectives</h2>
            <ul className="about-objectives">
              <li>
                <h3>Customer Convenience</h3>
                <p>
                  A seamless, user-friendly platform for managing on-demand storage
                  services.
                </p>
              </li>
              <li>
                <h3>Affordable Pricing</h3>
                <p>
                  Transparent pricing with fair adjustments to meet market
                  fluctuations.
                </p>
              </li>
              <li>
                <h3>Reliability and Trust</h3>
                <p>
                  Security measures that ensure your belongings are in safe hands.
                </p>
              </li>
              <li>
                <h3>Sustainable Growth</h3>
                <p>
                  Expanding across regions while maintaining quality and positive
                  community impact.
                </p>
              </li>
              <li>
                <h3>Technological Innovation</h3>
                <p>
                  Continuous improvements driven by customer feedback and modern
                  technology.
                </p>
              </li>
            </ul>
          </div>
        </div>
      </section>

      {/* ===== Contact Us Section ===== */}
      <section id="contactUs-section" className="contact-section">
        <div className="contact-container">
          <div className="contact-input">
            <h2>Contact Us</h2>
            <p>We’d love to hear from you! Fill out the form below.</p>
            <input
              type="text"
              name="name"
              placeholder="Your Name"
              value={name}
              onChange={(e) => setName(e.target.value)}
            />
            <input
              type="email"
              name="email"
              placeholder="Your Email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
            <textarea
              name="message"
              placeholder="Your Message"
              value={message}
              onChange={(e) => setMessage(e.target.value)}
            />
            <button onClick={handleSendClick}>
              {status === "waiting" ? "Please wait..." : "Send"}
            </button>
          </div>
          <div className="contact-info">
            <h2>Get in Touch</h2>
            <div className="contact-details">
              <p>
                <FaPhoneAlt className="contact-icon" /> +27 609 881 456
              </p>
              <p>
                <MdOutlineMail className="contact-icon" /> storage.sprint@gmail.com
              </p>
            </div>
          </div>
        </div>
      </section>

      <Footer />
    </div>
  );
};

export default Home;
