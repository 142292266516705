import React, { useState, useEffect } from 'react';
import { FaMapMarkerAlt, FaRoute, FaStar } from 'react-icons/fa';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import CustomerPage from '../../homeDashboard/templates/CustomerPage';
import { decryptData } from '../../utils/encryption';
import DriverPage from '../../driverDashboard/template/DriverPage';
import '../static/css/tripDetails.css'; // Assuming CSS is stored here

const TripDetail = () => {
    const { tripId } = useParams(); // Get trip reference from the URL
    const [trip, setTrip] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState('');
    const [role, setRole] = useState(null); // Always keep hooks at the top level of the component

    // Hook to fetch role
    useEffect(() => {
        const roleData = decryptData('storageData')?.role;
        setRole(roleData);
    }, []);

    // Hook to fetch trip details
    useEffect(() => {
        // Fetch the trip details from the API using axios
        const fetchTripDetails = async () => {
            try {
                const response = await axios.get(`/api/get-trip-details/${tripId}/`, {
                    headers: {
                        'Content-Type': 'application/json',
                    },
                });
                setTrip(response.data);
                setLoading(false);
            } catch (err) {
                console.error('Error fetching trip details:', err);
                setError('Failed to load trip details.');
                setLoading(false);
            }
        };

        fetchTripDetails();
    }, [tripId]); // Trigger the effect when tripId changes

    // Ensure hooks aren't conditionally called, check role after hook calls
    if (loading) {
        return <p>Loading trip details...</p>;
    }

    if (error) {
        return <p>{error}</p>;
    }

    if (!trip) {
        return <p>Trip not found.</p>;
    }

    // Handle conditional rendering based on role
    if (!role) {
        return <div>Loading role...</div>;  // Show loading state until role is fetched
    }

    const MasterComponent = role === 'driver' || role === 'admin' ? DriverPage : CustomerPage;

    const isCompleted = trip.status === 'Pending' || trip.status === 'In Progress' || trip.status === 'Driving to Pickup';

    return (
        <MasterComponent>
            <div style={styles.container}>
                <h2>Trip Details</h2>
                <div style={styles.driverInfo}>
                    <span>Driver: <strong>{trip.driverName || 'No driver assigned'}</strong></span>
                    <span>Phone: {trip.phoneNumber || 'Not available'}</span>
                </div>

                <div style={styles.tripInfo}>
                    <div style={styles.location}>
                        <FaMapMarkerAlt /> {trip.pickupLocation}
                    </div>
                    <div style={styles.location}>
                        <FaMapMarkerAlt style={{ color: 'red' }} /> {trip.dropoffLocation}
                    </div>
                    <div style={styles.status}>
                        <FaRoute /> {trip.status}
                    </div>
                </div>

                <div style={styles.actions}>
                    {isCompleted ? (
                        <button style={styles.rateButton}>
                            <FaStar className="rate-icon" /> Rate
                        </button>
                    ) : (
                        <button style={styles.cancelButton}>Cancel</button>
                    )}
                </div>
            </div>
        </MasterComponent>
    );
};

// Basic styling
const styles = {
    container: {
        border: '1px solid #ddd',
        padding: '20px',
        borderRadius: '10px',
        width: '300px',
        margin: '0 auto',
        fontFamily: 'Arial, sans-serif',
    },
    driverInfo: {
        marginBottom: '15px',
    },
    tripInfo: {
        backgroundColor: '#f9f9f9',
        padding: '10px',
        borderRadius: '8px',
        marginBottom: '15px',
    },
    location: {
        marginBottom: '5px',
    },
    status: {
        marginBottom: '10px',
        color: '#4CAF50',
    },
    actions: {
        display: 'flex',
        justifyContent: 'space-between',
    },
    rateButton: {
        backgroundColor: '#FFD700',
        border: 'none',
        padding: '8px 15px',
        borderRadius: '5px',
        cursor: 'pointer',
    },
    cancelButton: {
        backgroundColor: '#FF6347',
        border: 'none',
        padding: '8px 15px',
        borderRadius: '5px',
        cursor: 'pointer',
        color: '#fff',
    },
};

export default TripDetail;
