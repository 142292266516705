// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* Footer styling 
----------------------------------------*/
.landpage-footer {
    font-style: normal;
    background-color: #ffffff;
    width: 100vw; /* Width is 80% of the viewport width */
    margin: 0 auto; /* Center the container horizontally */
    box-sizing: border-box; /* Includes padding and border in the width */
    color: #000000;
    width: 100%;
    height: auto;
    position: relative; /* Change this from 'fixed' to 'relative' */
    align-items: center;
    bottom: 0; 
    left: 0; 
    z-index: 1; 
}

.landpage-footer-Content {
    text-align: center;
    max-width: 900px; 
    margin: 0 auto;
}

.landpage-footer-links {
    display: flex;
    color: #000000;    
    justify-content: center;
    gap: 20px;
}

.landpage-footer-links a {
    color: #000000;
    text-decoration: none;
    font-size: 14px;
}

.landpage-footer-links a:hover {
    color: #ffd600;
}

.Footer-copyright {
    text-align: center;
    margin-top: 10px;
}
 
`, "",{"version":3,"sources":["webpack://./src/landingPage/static/Footer.css"],"names":[],"mappings":"AAAA;yCACyC;AACzC;IACI,kBAAkB;IAClB,yBAAyB;IACzB,YAAY,EAAE,uCAAuC;IACrD,cAAc,EAAE,sCAAsC;IACtD,sBAAsB,EAAE,6CAA6C;IACrE,cAAc;IACd,WAAW;IACX,YAAY;IACZ,kBAAkB,EAAE,2CAA2C;IAC/D,mBAAmB;IACnB,SAAS;IACT,OAAO;IACP,UAAU;AACd;;AAEA;IACI,kBAAkB;IAClB,gBAAgB;IAChB,cAAc;AAClB;;AAEA;IACI,aAAa;IACb,cAAc;IACd,uBAAuB;IACvB,SAAS;AACb;;AAEA;IACI,cAAc;IACd,qBAAqB;IACrB,eAAe;AACnB;;AAEA;IACI,cAAc;AAClB;;AAEA;IACI,kBAAkB;IAClB,gBAAgB;AACpB","sourcesContent":["/* Footer styling \n----------------------------------------*/\n.landpage-footer {\n    font-style: normal;\n    background-color: #ffffff;\n    width: 100vw; /* Width is 80% of the viewport width */\n    margin: 0 auto; /* Center the container horizontally */\n    box-sizing: border-box; /* Includes padding and border in the width */\n    color: #000000;\n    width: 100%;\n    height: auto;\n    position: relative; /* Change this from 'fixed' to 'relative' */\n    align-items: center;\n    bottom: 0; \n    left: 0; \n    z-index: 1; \n}\n\n.landpage-footer-Content {\n    text-align: center;\n    max-width: 900px; \n    margin: 0 auto;\n}\n\n.landpage-footer-links {\n    display: flex;\n    color: #000000;    \n    justify-content: center;\n    gap: 20px;\n}\n\n.landpage-footer-links a {\n    color: #000000;\n    text-decoration: none;\n    font-size: 14px;\n}\n\n.landpage-footer-links a:hover {\n    color: #ffd600;\n}\n\n.Footer-copyright {\n    text-align: center;\n    margin-top: 10px;\n}\n \n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
