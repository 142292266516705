import React, { useState, useEffect } from 'react';
import { MapContainer, TileLayer, Marker, useMapEvents } from 'react-leaflet';
import L from 'leaflet';
import 'leaflet/dist/leaflet.css';
import axios from 'axios';
import '../static/css/showMap.css';
import markerIconUrl from '../static/images/game-icons--position-marker.svg'; // Import the icon directly
import PropTypes from 'prop-types'; // Import PropTypes for validation

const markerIcon = new L.Icon({
  iconUrl: markerIconUrl, // Use the imported icon URL
  iconSize: [25, 41],
  iconAnchor: [12, 41],
  popupAnchor: [1, -34],
});

const ShowMap = ({ onSelectLocation, pickupAddress, dropoffAddress, currentLocation, isFetchingLocation, currentField }) => {
  const [position, setPosition] = useState(currentLocation);

  useEffect(() => {
    setPosition(currentLocation);
  }, [currentLocation]);

  useEffect(() => {
    const searchAddress = async (address) => {
      if (!address) return;

      try {
        const { data } = await axios.get('https://nominatim.openstreetmap.org/search', {
          params: {
            q: address,
            format: 'json',
          },
        });

        if (data.length > 0) {
          const { lat, lon } = data[0];
          setPosition({ lat: parseFloat(lat), lng: parseFloat(lon) });
          onSelectLocation(data[0].display_name);
        } else {
          alert('No location found. Please refine your search.');
        }
      } catch (error) {
        console.error('Error searching for location:', error);
        alert('An error occurred while searching for the location. Please try again later.');
      }
    };

    if (currentField === 'pickup' && pickupAddress) {
      searchAddress(pickupAddress);
    } else if (currentField === 'dropoff' && dropoffAddress) {
      searchAddress(dropoffAddress);
    }
  }, [pickupAddress, dropoffAddress, currentField, onSelectLocation]);

  const LocationMarker = () => {
    useMapEvents({
      click(e) {
        setPosition(e.latlng);
        fetchAddress(e.latlng);
      },
    });

    return position ? <Marker position={position} icon={markerIcon} /> : null;
  };

  const fetchAddress = async ({ lat, lng }) => {
    try {
      const { data } = await axios.get(`https://nominatim.openstreetmap.org/reverse`, {
        params: {
          format: 'jsonv2',
          lat,
          lon: lng,
        },
      });
      const address = data.display_name;
      onSelectLocation(address);
    } catch (error) {
      console.error("Error fetching address:", error);
      alert('An error occurred while fetching the address. Please try again later.');
    }
  };

  return (
    <div className="map-container">
      {isFetchingLocation ? (
        <div className="loading-spinner">Fetching current location...</div>
      ) : (
        <MapContainer
          center={position || [-26.2041, 28.0473]} // Set a default center
          zoom={13}
          className="map-container-leaflet"
        >
          <TileLayer
            attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
            url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
          />
          <LocationMarker />
        </MapContainer>
      )}
    </div>
  );
};

// Add PropTypes validation for the props
ShowMap.propTypes = {
  onSelectLocation: PropTypes.func.isRequired,
  pickupAddress: PropTypes.string,
  dropoffAddress: PropTypes.string,
  currentLocation: PropTypes.shape({
    lat: PropTypes.number,
    lng: PropTypes.number,
  }),
  isFetchingLocation: PropTypes.bool.isRequired,
  currentField: PropTypes.string.isRequired,
};

export default ShowMap;
