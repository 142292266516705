import React, { useState, useEffect } from 'react';
import { MdAccountBalance } from 'react-icons/md';
import { Helmet } from 'react-helmet';
import '../static/css/earnings.css'; 
import DriverPage from './DriverPage';
import { decryptData } from '../../utils/encryption'; 

const Earnings = () => {
  const [period, setPeriod] = useState('7'); // Default to 7 days
  const [trips, setTrips] = useState([]);
  const [totalEarnings, setTotalEarnings] = useState(0);
  const email = decryptData('storageData')?.email;

  useEffect(() => {
    const fetchEarnings = async () => {
      try {
        const response = await fetch(`/api/completed-trips/${email}/${period}/`, {
          method: 'GET',
          headers: { 'Content-Type': 'application/json' },
        });

        if (response.ok) {
          const data = await response.json();
          console.log('Fetched earnings data:', data.trips);
          setTrips(data.trips); 
          
          const earnings = data.trips.reduce((sum, trip) => sum + (trip.amount || 0), 0);
          setTotalEarnings(earnings);
        } else {
          console.error('Failed to fetch earnings data.');
        }
      } catch (error) {
        console.error('Error fetching trips:', error);
      }
    };

    fetchEarnings();
  }, [period, email]);

  return (
    <DriverPage>
      <Helmet>
        <title>Earnings | Storage Sprint</title> 
      </Helmet>

      <div className="earnings-container">
        <h1 className="page-title"><MdAccountBalance /> Total Earnings</h1>

        <div className="period-selector">
          <label htmlFor="period">Filter by period:</label>
          <select id="period" value={period} onChange={(e) => setPeriod(e.target.value)}>
            <option value="1">Last 1 day</option>
            <option value="3">Last 3 days</option>
            <option value="7">Last 7 days</option>
            <option value="30">Last 30 days</option>
          </select>
        </div>

        <div className="earnings-summary">
          <h2>Total Earnings: <span className="total-amount">R{totalEarnings.toFixed(2)}</span></h2>
        </div>

        <div className="trips-list">
          <h2>Trip Details</h2>
          <table className="trips-table">
            <thead>
              <tr>
                <th>Reference</th>
                <th>Pickup Location</th>
                <th>Dropoff Location</th>
                <th>Amount</th>
              </tr>
            </thead>
            <tbody>
              {trips.length > 0 ? (
                trips.map((trip, index) => (
                  <tr key={index}>
                    <td>{trip.reference || `Trip-${index + 1}`}</td>
                    <td>{trip.pickupLocation}</td>
                    <td>{trip.dropoffLocation}</td>
                    <td>R{(trip.amount || 0).toFixed(2)}</td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td colSpan="4">No trips found for this period.</td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      </div>
    </DriverPage>
  );
};

export default Earnings;
