import React from "react";
import Footer from "./Footer";
import NavBar from "./NavBar";
import "../static/Privacy.css";
import {Helmet} from "react-helmet";

const Privacy = () => {
    return (
        <div>
          <Helmet>
                <title>Privacy | Storage Sprint</title> 
        </Helmet>
            <NavBar />
         <div className="Privacy-container">
        <h2>Privacy Policy for Storage Sprint</h2>
        <h3>Effective Date: 26/01/2025 </h3>
        <p>
        At Storage Sprint ("we," "our," or "us"), your privacy is important to us. This Privacy Policy 
explains how we collect, use, and protect your personal information when you use our 
services, website, and mobile application (collectively, the "Services"). By using the 
Services, you agree to the practices described in this policy.
        </p>
            <ol className="Privacy-list">
                <li>
                Information We Collect
                </li>
                <p>
                We collect information to provide and improve our Services, ensure security, and 
comply with legal obligations. This includes: 
                </p>
                <p>a. Information You Provide</p>
                <ul className="Privacy-sublist">
                    <li><b>Account Information</b>: Name, email address, phone number, password, and 
                    profile photo (optional). </li>
                    <li><b>Payment Details</b>: Billing address and payment method information (e.g., credit 
                        card or bank details). Note: We do not store payment information; this is 
                        processed by secure third-party payment providers. </li>
                    <li><b>Storage and Delivery Details</b>: Pickup and drop-off addresses, item 
                    descriptions, weight, and quantity. </li>
                </ul>
                <p>b. Information We Collect Automatically </p>
                <ul className="Privacy-sublist">
                    <li><b>Usage Data</b>: Details of your interactions with the Services, including order 
                    history, preferences, and app usage patterns. </li>
                    <li><b>Location Data</b>: Real-time location to facilitate deliveries and provide accurate 
                    pricing.</li>
                    <li><b>Device Information</b>: IP address, device type, operating system, and app version.</li>
                </ul>
                <p>c. Information from Third Parties</p>
                <ul className="Privacy-sublist">
                    <li>Social media platforms (if you link your account).</li>
                    <li>Public databases or business partners (for verification purposes). </li>
                </ul>
                <li>How We Use Your Information</li>
                <p>We use your information to: </p>
                <ul className="Privacy-sublist">
                    <li>Provide, manage, and improve the Services. 
                    </li>
                    <li>Process orders, payments, and deliveries.</li>
                    <li>Calculate accurate pricing based on distance, weight, and quantity.</li>
                    <li>Communicate with you regarding updates, promotions, and support. </li>
                    <li>Ensure safety and prevent fraud. </li>
                    <li>Comply with legal obligations. </li>
                </ul>
                <li>How We Share Your Information </li>
                <p>We do not sell your personal information. However, we may share your data with:</p>
                <ul className="Privacy-sublist">
                    <li><b>Service Providers</b>: Delivery drivers, payment processors, and customer support 
                    teams. </li>
                    <li><b>Business Partners</b>: For joint marketing and promotional efforts.</li>
                    <li><b>Legal Authorities</b>: When required by law or to protect our rights. </li>
                    <li><b>Third-Party Integrations</b>: If you opt to use features like social media login.</li>
                </ul>
                <li> Data Security</li>
                <p>We implement industry-standard security measures to protect your data. These include 
                    encryption, access controls, and regular security assessments. However, no system is 
                    completely secure, and we cannot guarantee absolute security. </p>
                <li>Your Rights </li>
                <p>You have the right to: </p>
                <ul className="Privacy-sublist">
                    <li><b>Access</b>: Request a copy of your personal data.</li>
                    <li><b>Correction</b>: Update or correct inaccurate information.</li>
                    <li><b>Deletion</b>: Request deletion of your data, subject to legal requirements.</li>
                    <li><b>Objection</b>: Opt-out of certain data uses, such as marketing.</li>
                    <li><b>Portability</b>: Receive your data in a structured, commonly used format.</li>
                </ul>
                <p>To exercise these rights, contact us at Storagesprint@gmail.co.za .</p>
               <li>Cookies and Tracking Technologies</li>
               <p>We use cookies and similar technologies to:</p>
               <ul className="Privacy-sublist">
                <li>Enhance user experience.</li>
                <li>Analyze usage patterns.</li>
                <li>Provide personalized content and advertisements.</li>
               </ul>
               <p>You can manage your cookie preferences through your browser settings.</p>
               <li>Retention of Information</li>
               <p>We retain your information only as long as necessary to provide the Services, comply 
               with legal obligations, resolve disputes, and enforce our agreements.</p>
               <li>Third-Party Links</li>
               <p>Our Services may contain links to third-party websites or services. We are not 
               responsible for their privacy practices. Please review their privacy policies.</p>
               <li>Children's Privacy</li>
               <p>Our Services are not intended for individuals under 18. We do not knowingly collect 
                personal information from children. If we become aware of such data, we will delete it 
                promptly.</p>
                <li> Changes to This Policy </li>
                <p>We may update this Privacy Policy from time to time. We will notify you of significant 
                changes through the Services or other means. Continued use of the Services after 
                updates constitutes acceptance of the revised policy.</p>
                <li>Contact Us</li>
                <p>If you have questions or concerns about this Privacy Policy, please contact us at:
                <p><b>Storage Sprint</b><br></br>Email: <b>Storage.sprint@gmail.com</b> <br></br>Phone: <b>0609881456</b>  </p>
                </p>
                </ol>
        </div>
            <Footer />
        </div>
    );
}

export default Privacy;