import CryptoJS from 'crypto-js';

export function encryptData(key, data) {
  const encryptedData = CryptoJS.AES.encrypt(JSON.stringify(data), 'qVC89VO$pbG41*wAJC(#HX4OzL#Y1rE').toString();
  localStorage.setItem(key, encryptedData);
}

export function decryptData(key) {
  const encryptedData = localStorage.getItem(key);
  if (!encryptedData) return null;

  const bytes = CryptoJS.AES.decrypt(encryptedData, 'qVC89VO$pbG41*wAJC(#HX4OzL#Y1rE');
  const decryptedData = bytes.toString(CryptoJS.enc.Utf8);
  return JSON.parse(decryptedData);
}
