import React from 'react';
import PropTypes from 'prop-types';  // Import PropTypes
import '../static/css/confirmModal.css';

const ConfirmModal = ({ isOpen, onClose, onConfirm, message }) => {
    if (!isOpen) return null;

    return (
        <div className="modal-overlay">
            <div className="modal modal-animate"> {/* Added a class for animation */}
                <h3>Confirm Change</h3>
                <p>{message}</p>
                <div className="modal-actions">
                    <button className="btn-modern btn-confirm-modern" onClick={onConfirm}>Confirm</button>
                    <button className="btn-modern btn-cancel-modern" onClick={onClose}>Cancel</button>
                </div>
            </div>
        </div>
    );
};

// Define prop types for the component
ConfirmModal.propTypes = {
    isOpen: PropTypes.bool.isRequired,      // isOpen must be a boolean
    onClose: PropTypes.func.isRequired,     // onClose must be a function
    onConfirm: PropTypes.func.isRequired,   // onConfirm must be a function
    message: PropTypes.string.isRequired,   // message must be a string
};

export default ConfirmModal;
