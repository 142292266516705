// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Privacy-container{
    padding: 100px min(5em, 8vh) 0;
    place-items: center;
}

.Privacy-container h2{
    color: black;
}
.Privacy-container ul {
        list-style-type: disc;
}

ol.Privacy-list li{
    font-weight: 775;
    text-decoration: underline;
}

ul.Privacy-sublist li{
    font-weight: 400;
    text-decoration: none;
}

.Privacy-container ol{
    padding-inline-start: 0;
}`, "",{"version":3,"sources":["webpack://./src/landingPage/static/Privacy.css"],"names":[],"mappings":"AAAA;IACI,8BAA8B;IAC9B,mBAAmB;AACvB;;AAEA;IACI,YAAY;AAChB;AACA;QACQ,qBAAqB;AAC7B;;AAEA;IACI,gBAAgB;IAChB,0BAA0B;AAC9B;;AAEA;IACI,gBAAgB;IAChB,qBAAqB;AACzB;;AAEA;IACI,uBAAuB;AAC3B","sourcesContent":[".Privacy-container{\n    padding: 100px min(5em, 8vh) 0;\n    place-items: center;\n}\n\n.Privacy-container h2{\n    color: black;\n}\n.Privacy-container ul {\n        list-style-type: disc;\n}\n\nol.Privacy-list li{\n    font-weight: 775;\n    text-decoration: underline;\n}\n\nul.Privacy-sublist li{\n    font-weight: 400;\n    text-decoration: none;\n}\n\n.Privacy-container ol{\n    padding-inline-start: 0;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
