import React from 'react';

const ContentUnavailable = () => {
    const styles = {
        container: {
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            height: '100vh',
            textAlign: 'center',
            padding: '20px',
            backgroundColor: '#f4f6f9',
            color: '#333',
            fontFamily: 'Arial, sans-serif',
        },
        heading: {
            fontSize: '2.5rem',
            marginBottom: '15px',
            color: '#ff4d4f',
        },
        message: {
            fontSize: '1.2rem',
            lineHeight: '1.6',
            maxWidth: '600px',
            marginBottom: '25px',
        },
        button: {
            padding: '10px 20px',
            fontSize: '1rem',
            backgroundColor: '#007bff',
            color: '#fff',
            border: 'none',
            borderRadius: '5px',
            cursor: 'pointer',
            textDecoration: 'none',
        },
        buttonHover: {
            backgroundColor: '#0056b3',
        },
    };

    return (
        <div style={styles.container}>
            <h2 style={styles.heading}>This content isn&apos;t available at the moment</h2>
            <p style={styles.message}>
                When this happens, it&apos;s usually because the owner only shared it with a small group of people, 
                changed who can see it, or it&apos;s been deleted.
            </p>
            <a
                href="/"
                style={styles.button}
                onMouseOver={(e) => (e.target.style.backgroundColor = styles.buttonHover.backgroundColor)}
                onMouseOut={(e) => (e.target.style.backgroundColor = styles.button.backgroundColor)}
            >
                Go Back to Home
            </a>
        </div>
    );
};

export default ContentUnavailable;
