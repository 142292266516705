// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* mapContainer.css */

.map-container {
  padding-top: 60px;
  width: 100%;
  height: 80%;
  border-radius: 20px;
}

.map-container-leaflet {
  padding-top: 40px;
  width: 100%;
  height: 90%;
  border-radius: 20px;

}

.loading-spinner {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 400px;
  font-size: 1.2rem;
  color: #666;
}

/* Adjust heights for different screen sizes */
@media (min-width: 768px) {
  .map-container {
    height: 600px;
  }
}

@media (min-width: 1024px) {
  .map-container {
    height: 650px;
  }
}
`, "",{"version":3,"sources":["webpack://./src/homeDashboard/static/css/showMap.css"],"names":[],"mappings":"AAAA,qBAAqB;;AAErB;EACE,iBAAiB;EACjB,WAAW;EACX,WAAW;EACX,mBAAmB;AACrB;;AAEA;EACE,iBAAiB;EACjB,WAAW;EACX,WAAW;EACX,mBAAmB;;AAErB;;AAEA;EACE,aAAa;EACb,uBAAuB;EACvB,mBAAmB;EACnB,aAAa;EACb,iBAAiB;EACjB,WAAW;AACb;;AAEA,8CAA8C;AAC9C;EACE;IACE,aAAa;EACf;AACF;;AAEA;EACE;IACE,aAAa;EACf;AACF","sourcesContent":["/* mapContainer.css */\n\n.map-container {\n  padding-top: 60px;\n  width: 100%;\n  height: 80%;\n  border-radius: 20px;\n}\n\n.map-container-leaflet {\n  padding-top: 40px;\n  width: 100%;\n  height: 90%;\n  border-radius: 20px;\n\n}\n\n.loading-spinner {\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  height: 400px;\n  font-size: 1.2rem;\n  color: #666;\n}\n\n/* Adjust heights for different screen sizes */\n@media (min-width: 768px) {\n  .map-container {\n    height: 600px;\n  }\n}\n\n@media (min-width: 1024px) {\n  .map-container {\n    height: 650px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
