import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import styles from '../static/css/home-dashboard.module.css';
import { FaMapMarkerAlt, FaFlag } from 'react-icons/fa';
import ShowMap from './ShowMap';
import { Helmet } from 'react-helmet';
import CustomerPage from './CustomerPage';
import axios from 'axios';
import { decryptData } from '../../utils/encryption'; 

const HomeDashboard = () => {

  const [pickupLocation, setPickupLocation] = useState('');
  const [dropoffLocation, setDropoffLocation] = useState('');
  const [suggestions, setSuggestions] = useState([]); // State for address suggestions
  const [showMap, setShowMap] = useState(false);
  const [currentField, setCurrentField] = useState(null);
  const [loading, setLoading] = useState(false);
  const errorMessage = useState('');
  const [currentLocation, setCurrentLocation] = useState(null);


  const [isFetchingLocation, setIsFetchingLocation] = useState(true);

  const userData = decryptData('storageData');
  const firstName = userData?.firstName;
  const navigate = useNavigate();

  useEffect(() => {
    const fetchCurrentLocation = () => {
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(
          (position) => {
            const { latitude, longitude } = position.coords;
            setCurrentLocation({ lat: latitude, lng: longitude });
            setIsFetchingLocation(false);
          },
          (error) => {
            console.error("Error getting location: ", error);
            setIsFetchingLocation(false);
          }
        );
      } else {
        console.error("Geolocation is not supported by this browser.");
        setIsFetchingLocation(false);
      }
    };

    fetchCurrentLocation();
  }, []);



  const handleMapSelect = (location) => {
    if (currentField === 'pickup') {
      setPickupLocation(location);
    } else if (currentField === 'dropoff') {
      setDropoffLocation(location);
    }
    setShowMap(false);
    setSuggestions([]); // Clear suggestions after selection
  };

  const handleFieldClick = (field) => {
    setCurrentField(field);
    setShowMap(true);
  };

  const handleContinue = () => {
    if (!pickupLocation || !dropoffLocation) {
      alert('Please fill in both Pickup and Dropoff locations.');
      return;

    
    }

    setLoading(true);
    setTimeout(() => {
      navigate('/package', {
        state: {
          pickupLocation,
          dropoffLocation,
        },
      });
    }, 1000);
  };

  // Fetch address suggestions from Nominatim
  const fetchSuggestions = async (value) => {
    try {
      const { data } = await axios.get('https://nominatim.openstreetmap.org/search', {
        params: {
          q: value,
          format: 'json',
          addressdetails: 1,
          limit: 5,
        },
      });

      setSuggestions(data.map((item) => item.display_name));
    } catch (error) {
      console.error('Error fetching suggestions:', error);
    }
  };

  const handleInputChange = (e, field) => {
    const value = e.target.value;
    if (field === 'pickup') {
      setPickupLocation(value);
    } else if (field === 'dropoff') {
      setDropoffLocation(value);
    }

    fetchSuggestions(value); // Fetch suggestions as user types
  };

  // Handle selecting a suggestion from the list
  const handleSuggestionClick = (suggestion) => {
    handleMapSelect(suggestion);
    if (currentField === 'pickup') {
      setPickupLocation(suggestion);
    } else if (currentField === 'dropoff') {
      setDropoffLocation(suggestion);
    }
    setSuggestions([]);
  };

  
  const handleKeyDown = (e) => {
    if (e.key === 'Enter' && suggestions.length > 0) {
      handleSuggestionClick(suggestions[0]);
    }
  };

  return (
    <CustomerPage>
      <Helmet>
        <title>Schedule a Delivery | Storage Sprint</title>
      </Helmet>

      <main>
        <div className={styles.mobileContainer}>
          <div className={styles.locationsContainer}>
            <div className={styles.contentMessage}>
              <h1>Welcome to Storage Sprint, {firstName}!</h1>
              <p>Schedule your delivery now!</p>
              <div className={styles.inputGroup}>
                <div className={styles.inputField}>
                  <FaMapMarkerAlt />
                  <input
                    type="text"
                    placeholder="Enter Pickup Location"
                    value={pickupLocation}
                    onChange={(e) => handleInputChange(e, 'pickup')}
                    onClick={() => handleFieldClick('pickup')}
                    onKeyDown={handleKeyDown}
                  />
                  {currentField === 'pickup' && suggestions.length > 0 && (
                    <ul className={styles.suggestionsList}>
                      {suggestions.map((suggestion, index) => (
                        <li key={index} onClick={() => handleSuggestionClick(suggestion)}>
                          {suggestion}
                        </li>
                      ))}
                    </ul>
                  )}
                </div>
                <div className={styles.inputField}>
                  <FaFlag />
                  <input
                    type="text"
                    placeholder="Enter Dropoff Location"
                    value={dropoffLocation}
                    onChange={(e) => handleInputChange(e, 'dropoff')}
                    onClick={() => handleFieldClick('dropoff')}
                    onKeyDown={handleKeyDown}
                  />
                  {currentField === 'dropoff' && suggestions.length > 0 && (
                    <ul className={styles.suggestionsList}>
                      {suggestions.map((suggestion, index) => (
                        <li key={index} onClick={() => handleSuggestionClick(suggestion)}>
                          {suggestion}
                        </li>
                      ))}
                    </ul>
                  )}
                </div>

                <button
                  className={styles.continueButton}
                  onClick={handleContinue}
                  disabled={loading}
                >
                  {loading ? 'Loading...' : 'Continue'}
                </button>
              </div>
              {errorMessage && <p className={styles.errorMessage}>{errorMessage}</p>}
            </div>

            <div className={styles.mapDisplay}>
              {showMap && (
                <ShowMap
                  onSelectLocation={handleMapSelect}
                  pickupAddress={pickupLocation}
                  dropoffAddress={dropoffLocation}
                  currentLocation={currentLocation}
                  isFetchingLocation={isFetchingLocation}
                />
              )}
            </div>
          </div>
        </div>
      </main>

      
    </CustomerPage>
  );
};

export default HomeDashboard;
