const data = {
    categories: [
      {
        name: 'Furniture',
        subcategories: [
          {
            name: 'Seating Furniture',
            products: ['Sofas', 'Armchairs', 'Stools', 'Benches'],
          },
          {
            name: 'Tables',
            products: ['Dining Tables', 'Coffee Tables', 'Desks', 'Side Tables'],
          },
          {
            name: 'Bedroom Furniture',
            products: ['Beds', 'Mattresses', 'Wardrobes', 'Dressers'],
          },
          {
            name: 'Storage Units',
            products: ['Bookshelves', 'Cabinets', 'Chests of Drawers'],
          },
          {
            name: 'Outdoor Furniture',
            products: ['Patio Sets', 'Loungers', 'Garden Benches'],
          },
        ],
      },
      
      {

        name: 'Boxes and Containers',
        subcategories: [
          {
            name: 'Small Boxes',
            products: ['Books', 'Clothing', 'Electronics'],
          },
          {
            name: 'Medium Boxes',
            products: ['Kitchenware', 'Household Items'],
          },
          {
            name: 'Large Boxes',
            products: ['Bulk Items', ' Heavier Equipment'],
          },
          {
            name: 'Specialized Containers',
            products: ['Fragile Goods (Glassware/Artwork)'],
          },
        ],
      },
     {
        name: 'Appliances',
        subcategories: [
            {
                name: 'Kitchen Appliances',
                products: ['Refrigerator', 'Stove', 'Dishwasher', 'Microwave'],
            },
            {
                name: 'Laundry Appliances',
                products: ['Washing Machine','Dryer'],
            },
            {
                name: 'Entertainment  Appliances',
                products: ['Television', 'Sound System'],
            },
            {
                name: 'Small Appliances',
                products: ['Toaster', 'Blender', 'Kettle'],
            },
        ],
     },
     {
        name: 'Office Equipment',
        subcategories: [
            {
                name: 'Office Furniture',
                products: ['Desk', 'Chair', 'Fling Cabinets'],
            },
            {
                name: 'Electronics',
                products: ['Printer', 'Monitor', 'Desktop Computer'],
            },
            {
                name: 'Storage Items',
                products: ['Document Boxes', 'Shelving Units'],
            }
        ]
     },
     {
        name: 'Sports and Musical Equipment',
        subcategories: [
            {
                name: 'Sports Gear',
                products: ['Bike', 'Treadmill','Weight Sets'],
            },
            {
                name: 'Camping Equipment',
                products: ['Tents', 'Grills','Sleeping Bags'],
            },
            {
                name: 'Musical Items',
                products: ['Instruments'],
            },

        ]
     },
     {
        name: 'Industrial or Specialized Equipment',
        subcategories: [
            {
                name: 'Power Tools',
                products: ['Drills', 'Saws','Compressors'],
            },
            {
                name: 'Construction Equipment',
                products: ['Ladders', 'Scaffolding'],
            },
            {
                name: 'Gardening Tools',
                products: ['Lawnmower','Wheelbarrow'],
            },
        ],
     },
     {
        name: 'Fragile and High-Value Items',
        subcategories: [
            {
                name: 'Artwork',
                products: ['Paintings', 'Sculptures'],
            },
            {
                name: 'Antiques',
                products: ['Vintage Furniture', 'Heirlooms'],
            },
            {
                name: 'High-Tech Items',
                products: ['Gaming Setups', 'Lab Equipment'],
            },
        ]
     },
     {
        name: 'Miscellaneous',
        subcategories: [
            {
                name: 'Toys',
                products: ['Playsets','Dollhouses'],
            },
            {
                name: 'Decorative Items',
                products: ['Mirrors','Chandeliers'],
            },
        ]
     }
    ],
  };

export default data;