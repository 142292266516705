import React, { useState , useEffect} from 'react';
import md5 from "md5";
import axios from 'axios';
import '../static/css/Transaction.css';

const Transaction = () => {
  const amount = 100

  const myData = {
    "merchant_id": "21395261",
    "merchant_key": "9fq9whIze3wer",
    "name_first": "John",
    "name_last": "Doe",
    "signature": "",
    "email_address": "john.doe@example.com",
    "m_payment_id": "00001",
    "amount": "100",
    "item_name": "#00001"
  };
  const passPhrase = 'jt7NOE43FZPn';

  const generateSignature = (data, passPhrase = null) => {
    let pfOutput = "";
    for (let key in data) {
      if (data.hasOwnProperty(key)) {
        if (data[key] !== "") {
          pfOutput += `${key}=${encodeURIComponent(data[key].trim()).replace(/%20/g, "+")}&`;
        }
      }
    }
  
    let getString = pfOutput.slice(0, -1);
    if (passPhrase !== null) {
      getString += `&passphrase=${encodeURIComponent(passPhrase.trim()).replace(/%20/g, "+")}`;
    }
  
    return md5(getString);
  };
  
  const dataToString = (dataArray) => {
    // Convert your data array to a string
    let pfParamString = "";
    for (let key in dataArray) {
      if(dataArray.hasOwnProperty(key)){pfParamString +=`${key}=${encodeURIComponent(dataArray[key].trim()).replace(/%20/g, "+")}&`;}
    }
    // Remove last ampersand
    return pfParamString.slice(0, -1);
  };
  
  const generatePaymentIdentifier = async (pfParamString) => {
    const result = await axios.post("https://sandbox.payfast.co.za/eng/process", pfParamString,{
      headers: {
        "Content-Type": "application/json",
      },
    })
        .then((res) => {
          return res.data.uuid || null;
        })
        .catch((error) => {
          console.error(error)
        });
    console.log("res.data", result);
    return result;
  };

  useEffect(() => {
    const script = document.createElement("script");
    script.src = "https://www.payfast.co.za/onsite/engine.js";
    script.async = true;
    script.onload = () => console.log("PayFast script loaded");
    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, []);
  

  const handlePayment = async () => {
    myData["signature"] = generateSignature(myData, passPhrase);
    console.log(myData["signature"]);
  
  // Convert the data array to a string
  const pfParamString = dataToString(myData);
  
  // Generate payment identifier
  const identifier = await generatePaymentIdentifier(pfParamString);
  console.log(identifier);
  if(identifier !== null){
    window.payfast_do_onsite_payment({"uuid":identifier});
  }
  };

  return (

    <div className="transaction-body">
      <div className="transaction-container">
        <div className="transaction-form-container">
          <h1 className="transaction-header">Make your payment here</h1> 
          <div className="transaction-form">
            <h3>Pay: ZAR {amount}</h3>
            <button type="submit" className="transaction-button" onClick={handlePayment}>Pay</button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Transaction;
