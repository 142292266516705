import React, { useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import '../static/css/PackageDescription.css'; 
import { decryptData } from '../../utils/encryption'; 
import CustomerPage from '../../homeDashboard/templates/CustomerPage';


const email = decryptData('storageData')?.email;

function PackageDescription() {
    const navigate = useNavigate();
    const location = useLocation(); 

    const pickupLocation = location.state?.pickupLocation || '';
    const dropoffLocation = location.state?.dropoffLocation || '';

    const [numPackages, setNumPackages] = useState(1);
    const [weights, setWeights] = useState([0]); 
    const [description, setDescription] = useState(''); 
    const [images, setImages] = useState(null); 
    const [loading, setLoading] = useState(false);


    const backButton = () => {
        navigate(-1);
    };

    const handleNumPackagesChange = (e) => {
        const count = parseInt(e.target.value, 10);
        setNumPackages(count);
        setWeights(Array(count).fill(0)); 
    };

    const handleWeightChange = (index, value) => {
        const newWeights = [...weights];
        newWeights[index] = value; 
        setWeights(newWeights);
    };

    const handleDescriptionChange = (e) => {
        setDescription(e.target.value);
    };

    const handleImageUpload = (e) => {
        setImages(e.target.files);
    };

    const handleContinue = async () => {
        // Basic validation
        if (weights.some(weight => weight === "")) {
            alert('Please select valid weight ranges for all packages.');
            return;
        }               
    
        if (!description) {
            alert('Please enter a description for the packages.');
            return;
        }
    
        setLoading(true);
        try {
            const formData = new FormData();
            formData.append('numPackages', numPackages);
            formData.append('description', description);
            formData.append('email', email);
            formData.append('pickupLocation', pickupLocation);
            formData.append('dropoffLocation', dropoffLocation);
    
            weights.forEach((weight, index) => {
                formData.append('weights', weight);
            });
    
            if (images) {
                Array.from(images).forEach((image) => {
                    formData.append('images', image);
                });
            }
    
            const response = await fetch('/api/payment/initiate/', {
                method: 'POST',
                credentials: 'include',
                body: formData,
            });
    
            if (response.ok) {
                const data = await response.json();
                
                navigate('/confirm-trip', {
                    state: {
                        pickupLocation,
                        dropoffLocation,
                        distance: data.distance,
                        amount: data.amount,
                        reference: data.reference,
                    },
                });
            } else {
                const errorData = await response.json();
                alert('Error: ' + (errorData.message || 'An error occured while processing your request. Please try again later.'));
            }
        } 
        
        catch (error) {
            alert('Unable to connect to the server at this time.');
            console.error('An error occurred', error);
        } finally {
            setLoading(false);
        }
    };

    
    return (

        <CustomerPage>

        <div className="package-container" style={{ height: '100vh', width: '100vw', display: 'flex', flexDirection: 'column' }}>
        
            <main className="main-content">
                <div className="input-row">
                    <input type="text" placeholder="From" value={pickupLocation} readOnly />
                    <input type="text" placeholder="To" value={dropoffLocation} readOnly />
                </div>

                <h2>Package Description <span role="img" aria-label="package">📦</span></h2>
                
                <div className="dropdown-section">
                    <label>Number of Packages:</label>
                    <select value={numPackages} onChange={handleNumPackagesChange}>
                        {[...Array(10)].map((_, i) => (
                            <option key={i + 1} value={i + 1}>{i + 1}</option>
                        ))}
                    </select>
                </div>

                <div className="weights-section">
                {weights.map((weight, index) => (
                    <div key={index} className="weight-input">
                        <label>Weight of Package {index + 1}:</label>
                        <select value={weight} onChange={(e) => handleWeightChange(index, e.target.value)}>
                            <option value="">Select weight range</option>
                            <option value="1-5kg">1-5 kg</option>
                            <option value="6-10kg">6-10 kg</option>
                            <option value="11-20kg">11-20 kg</option>
                            <option value="21-30kg">21-30 kg</option>
                            <option value="31-50kg">31-50 kg</option>
                        </select>
                    </div>
                    ))}
                </div>


                <label>Enter a full description</label>
                <textarea 
                    placeholder="Package description..." 
                    value={description} 
                    onChange={handleDescriptionChange} 
                />

                <p>OR</p>

                <div className="upload-section">
                    <label>Upload images of the package</label>
                    <input type="file" multiple onChange={handleImageUpload} />
                </div>

                <button className="package-button" onClick={handleContinue} disabled={loading}>
                    {loading ? 'Loading...' : 'Continue'}
                </button>
            </main>
        </div>

        </CustomerPage>
    );
}

export default PackageDescription;
