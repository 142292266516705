import React, { useState } from "react";
import Logo from "../../logos/storage-sprint.png";
import "../static/NavBar.css";
import { useNavigate, useLocation } from "react-router-dom";
import { TiThMenu } from "react-icons/ti";

const NavBar = () => {
  const [isSidebarOpen, setSidebarOpen] = useState(false);
  const location = useLocation();
  const navigate = useNavigate();

  const handleLogin = () => {
    navigate("/login");
  };

  const toggleSidebar = () => {
    setSidebarOpen(!isSidebarOpen);
  };

  const handleClick = (e, pageId) => {
    e.preventDefault(); // Prevent default anchor behavior

    if (location.pathname === "/") {
      // Scroll to the Services section if already on the Home page
      const pageSection = document.getElementById(pageId);
      if (pageSection) {
        pageSection.scrollIntoView({ behavior: "smooth" });
      }
    } else {
      // Navigate to /home and store a flag to scroll after navigation
      navigate("/", { state: { scrollToServices: pageId } });
    }
  };

  return (
    <header className="NavBar-header">
      <div className="NavBar-navigation">
        <a href="/" className="NavBar-logo">
          <img src={Logo} alt="Storage Sprint Logo" className="NavBar-logo-img" />
        </a>
        <button className="NavBar-hamburger" onClick={toggleSidebar}>
          <TiThMenu />
        </button>
      </div>

      <div className={`NavBar-sidebar ${isSidebarOpen ? "open" : ""}`}>
      <nav className="NavBar">
          <a href="#home" onClick={(e) => handleClick(e, "home-section")}>Home</a>
          {/* Use onClick for handleServicesClick */}
          <a href="#services" onClick={(e) => handleClick(e, "services-section")}>
            Services
          </a>
          <a href="#about-us" onClick={(e) => handleClick(e, "aboutUs-section")}>About Us</a>
          <a href="#contact-us" onClick={(e) => handleClick(e, "contactUs-section")}>Contact Us</a>
        </nav>
        <div className="NavBar-buttons">
          <button className="NavBar-button-login" onClick={handleLogin}>
            Login
          </button>
        </div>
      </div>
    </header>
  );
};

export default NavBar;
